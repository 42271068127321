import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useFormik } from 'formik';

import getUrlParameter from '../utils/getUrlParameter';
import { palette } from '../theme/palette';
import {
  SymptomsPageSignOutBtnPressed,
  trackOnLoadedScreenEvent,
} from '../actions/segment';
import {
  setIsFetching,
  setShowBanner,
  setShowRightSidedBannerVisibility,
} from '../actions';
import { ZERO_COST_PARTNERS } from '../constants/subscriptionStatus';
import { localStorageGet, localStorageSave } from '../utils/localStorageHelper';
import useComponentFocus from '../hooks/useComponentFocus';
import AnimatedRoute from '../components/AnimatedRoute';
import OshiNextButton from '../components/OshiNextButton';
import usePatientAPI from '../hooks/usePatientAPI';
import SymptomsTextArea from '../components/SymptomsTextArea';
import useBookApptFlowSegmentTrackerOnPageView from '../hooks/useBookApptFlowSegmentTrackerOnPageView';
import SignedInAs from '../components/SignedInAs';
import usePatientProfile from '../hooks/usePatientProfile';
import NewCustomTitle from '../components/NewCustomTitle';
import useWindowSize from '../hooks/useWindowSize';

const AppointmentConfirm = ({
  SymptomsPageSignOutBtnPressed,
  setIsFetching,
  trackOnLoadedScreenEvent,
  setShowRightSidedBannerVisibility,
  setShowBanner,
}) => {
  const history = useHistory();
  const { screenSize } = useWindowSize();
  const [shouldShowBanner, setShouldShowBanner] = useState(false);
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const { patientProfile } = usePatientProfile();
  const native = getUrlParameter('platform', history) === 'native';
  const { appointmentType: appointmentTypeFromWebview } = localStorageGet(
    'appointmentType'
  );
  const userIdFromWebview = getUrlParameter('uid', history);
  const [headlineRef, setHeadlineFocus] = useComponentFocus();
  const { customPatientUpdate } = usePatientAPI();
  const symptomsLSValue = localStorageGet('symptoms') || '';

  useBookApptFlowSegmentTrackerOnPageView(
    appointmentTypeFromWebview,
    trackOnLoadedScreenEvent
  );

  const formik = useFormik({
    initialValues: {
      symptoms: symptomsLSValue || '',
    },
  });

  const enterSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleReserveAppt();
    }
  };

  const handleSymptomsChange = (ev) => {
    formik.handleChange(ev);
  };

  useEffect(() => {
    if (!patientProfile) {
      setIsFetching(true);
    } else {
      setIsFetching(false);
      setShouldShowBanner(
        ZERO_COST_PARTNERS.includes(patientProfile.partner_system_id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientProfile]);

  useEffect(
    () => {
      if (!native && shouldShowBanner) {
        setShowBanner({
          text: (
            <BannerText>
              Covered by your employer at{' '}
              <span style={{ color: palette.melon }}>$0</span> cost to you!
            </BannerText>
          ),
          sentiment: 'information',
        });
      }

      // eslint-disable-next-line no-undef
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

      return () => {
        setShowBanner(false);
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldShowBanner]
  );

  useEffect(() => {
    headlineRef.current && setHeadlineFocus(headlineRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHeadlineFocus]);

  // Always show right sided banner after privacy policy page
  useEffect(() => {
    setShowRightSidedBannerVisibility(false);
    // eslint-disable-next-line
  }, []);

  async function handleReserveAppt() {
    try {
      setIsFetching(true);
      if (formik.values.symptoms.length >= 3) {
        localStorageSave('symptoms', formik.values.symptoms);
        await customPatientUpdate(
          {
            symptoms: formik.values.symptoms,
          },
          userIdFromWebview
        );
      }

      history.push('first-appointment-book');
    } catch (error) {
      console.log('error', error);
    }
  }

  const getTitle = (title, subHeader) => {
    return (
      <NewCustomTitle
        setShowBanner={null}
        hasZeroCostPartner={false}
        title={title}
        subHeader={subHeader}
      />
    );
  };

  const btnStyles = {};

  if (patientProfile) {
    btnStyles.width = '100%';
  }

  return (
    <AnimatedRoute
      nextRoute='/first-appointment-confirm'
      title={getTitle(
        'What brings you to Oshi Health today?',
        `Please share why you're seeking care, including any symptoms
      you'd like to discuss.`
      )}
      animationDirection={animationDirection}
      showProgressBar={false}
      setAnimationDirection={setAnimationDirection}
    >
      <SymptomsTextArea
        native={native}
        appointmentTypeFromWebview={appointmentTypeFromWebview}
        formik={formik}
        handleSymptomsChange={handleSymptomsChange}
        enterSubmit={enterSubmit}
      />

      <OshiNextButton
        styles={btnStyles}
        containerStyles={{ marginTop: screenSize.isMobile ? '0' : '170px' }}
        buttonTitle='Continue'
        onClick={handleReserveAppt}
      >
        <SignedInAs
          signOutEvent={SymptomsPageSignOutBtnPressed}
          styles={{ flex: 1, alignItems: 'flex-end', justifyContent: 'center' }}
        />
      </OshiNextButton>
    </AnimatedRoute>
  );
};

const BannerText = styled.span`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 136%;
  /* or 19px */

  letter-spacing: 0.04em;
  font-feature-settings: 'liga' off;

  /* error / error-500 */

  color: ${palette.white};
`;

const mapStateToProps = ({ uiReducer }) => ({
  isFetching: uiReducer.isFetching,
});
export default connect(mapStateToProps, {
  setIsFetching,
  trackOnLoadedScreenEvent,
  SymptomsPageSignOutBtnPressed,
  setShowBanner,
  setShowRightSidedBannerVisibility,
})(AppointmentConfirm);
