import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { palette } from '../theme/palette';
import { ChevronDownIcon, RadioCheckMark } from '../assets/svgs';
import CheckCircle from '../atoms/CheckCircle';

const collapsedHeight = '60px';

function ExpandableComponent({
  children,
  handleCheckOnClick,
  title,
  componentKey,
  isChecked,
  error,
  isExpanded,
  onClick,
  onScroll,
}) {
  const heightProps = useSpring({
    config: { mass: 1, tension: 180, friction: 20 },
    height: isExpanded ? '280px' : collapsedHeight,
  });

  const backgroundAnime = useSpring({
    backgroundColor: error ? palette.errorBackground : palette.background,
    borderColor: error ? palette.error : '#E0E3E5',
  });

  const arrowAnime = useSpring({
    transform: isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)',
  });

  return (
    <Container style={{ ...heightProps, ...backgroundAnime }}>
      <ClickableContainer onClick={() => onClick(componentKey)}>
        <CheckCircleContainer>
          <CheckCircle
            {...{ componentKey }}
            onClick={handleCheckOnClick}
            borderColor={error ? palette.error : '#c2c7cc'}
            nonFilledColor={palette.white}
            isFilled={isChecked}
          >
            <RadioCheckMark />
          </CheckCircle>
        </CheckCircleContainer>

        <TitleContainer>
          <Title styles={{ color: error ? palette.error : null }}>
            {title}
          </Title>
        </TitleContainer>

        <ChevronContainer style={arrowAnime}>
          <ChevronDownIcon color={error && palette.error} />
        </ChevronContainer>
      </ClickableContainer>

      {isExpanded && (
        <ContentContainer onScroll={(e) => onScroll(e, componentKey)}>
          {children}
        </ContentContainer>
      )}
    </Container>
  );
}

const Container = styled(animated.div)`
  width: 100%;
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  position: relative;
  margin: 5px 0;
`;

const ClickableContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${collapsedHeight};
  width: 100%;
  display: flex;
  align-items: center;
`;

const ChevronContainer = styled(animated.div)`
  padding: 0 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
`;

const Title = styled.p`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.03em;
  color: ${palette.coolGray};
`;

const CheckCircleContainer = styled.div`
  padding: 0 20px;
`;

const ContentContainer = styled.div`
  margin-top: ${collapsedHeight};
  flex: 1;
  overflow-y: scroll;
  padding: 0 15px 15px;

  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${palette.coolGray};

  letter-spacing: 0.04em;
`;

export default ExpandableComponent;
