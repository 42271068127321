import React from 'react';
import styled from 'styled-components';

/**
 * @function OshiRadioGroup
 * @description Custom and reusable UI component to group radio-button based UI components.
 * @param {React.ReactNode} children - Custom radio-button based UI components to be displayed within this group. 
 * @param {styled.CSSObject} styles - Custom CSS object style to customize this component root element. 
 * @returns {React.ReactElement}
 */
const OshiRadioGroup = ({ styles = {}, children }) => {
  return <RadioGroup style={styles}>{children}</RadioGroup>;
};

const RadioGroup = styled.fieldset`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  width: 100%;
`;

export default OshiRadioGroup;
