import React from 'react';
import styled from 'styled-components';

const MAX_ITEMS = 3;

/**
 * @function OshiACResultList
 * @module components/OshiACResultList
 * @description Renders the autocomplete result list when list data is not empty
 * The UI list gets saved in a state in order to manipulate basic UI DOM style changes like
 * highlighting the selected item either by mouse or keyboard (arrow keys), set fixed height,
 * and scrolling the list from its parent component custom hook.
 * @param {{list: Array, onItemClick: Function, setRenderedList: Function}} props
 * @param {React.Ref} ref
 * @returns {JSX.Element}
 */
const OshiACResultList = React.forwardRef(
  ({ list, onItemClick, setRenderedList }, ref) => {
    const [itemHeight, setItemHeight] = React.useState(0);

    React.useEffect(() => {
      setRenderedList(ref.current.children);
      setItemHeight(
        Array.from(ref.current.children)
          .slice(0, MAX_ITEMS)
          .reduce((acc, it) => acc + it.clientHeight, 0)
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    return (
      <ResultContainer
        ref={ref}
        hasoverflow={(list.length > MAX_ITEMS).toString()}
        style={{
          maxHeight: list.length > MAX_ITEMS ? itemHeight : 'auto',
        }}
      >
        {list.map((it) => (
          <ResultItem key={it.value} id={it.value} onClick={onItemClick}>
            <ResultText>{it.label}</ResultText>
          </ResultItem>
        ))}
      </ResultContainer>
    );
  }
);

const ResultContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  left: 0;
  top: 0;
  background: rgb(255, 255, 255);
  position: absolute;
  z-index: 10;
  border-radius: 4px;
  overflow-y: ${(props) =>
    props.hasoverflow === 'true' ? 'scroll' : 'hidden'};
`;

const ResultItem = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background: transparent;
  border-radius: 4px;
  padding: 10px 10px 10px 3%;
  color: rgb(59, 91, 128);
  cursor: default;
  &.selected,
  &:hover {
    background: rgb(59, 91, 128);
    color: rgb(255, 255, 255);
  }
`;

const ResultText = styled.span`
  font-size: 14px;
  font-family: Usual;
  line-height: 24px;
`;

export default OshiACResultList;
