import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactGA from 'react-ga';
import { datadogRum } from '@datadog/browser-rum';

import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './aws-exports';
import runAxe from './react-axe.config';
import { setViewportHeight } from './utils/domHelpers';
import './index.css';

setViewportHeight();

ReactGA.initialize(process.env.GA_KEY);

runAxe();

Sentry.init({
  dsn:
    'https://b5d7a30867ea495898589e78f3da67d5@o429510.ingest.sentry.io/5376324',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.ENV || 'dev',
});

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APP_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'oshi-onboarding',
  env: process.env.ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: '0.1.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
});

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}

gtag('js', new Date());

gtag('config', 'UA-127502514-1');

Amplify.configure(config);
ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
