import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { API } from 'aws-amplify';

import { palette } from '../theme/palette';
import { AppointmentContext } from '../utils/context';
import {
  AppointmentOverviewNote,
  AppointmentOverviewNoteSent,
  AppointmentOverviewNoNoteSent,
  AppointmentOverviewCoachBio,
  TrackMembershipConfirmation,
  TrackAppointmentTaken,
  TrackAppointmentTakenSheduled,
  trackBookAnotherAppointmentEvent,
  trackBackToAppointmentsEvent,
  trackOnLoadedScreenEvent,
  ApptOverviewPageSignOutBtnPressed,
} from '../actions/segment';

import { setIsFetching, setShowBanner } from '../actions';
import getUrlParameter from '../utils/getUrlParameter';
import { localStorageGet } from '../utils/localStorageHelper';
import usePatientProfile from '../hooks/usePatientProfile';
import {
  ONBOARDING_APPT_TYPE,
  appointmentStatuses,
} from '../constants/appointment';
import OshiDownloadDisplay from '../components/OshiDownloadDisplay';
import useBookApptFlowSegmentTrackerOnPageView from '../hooks/useBookApptFlowSegmentTrackerOnPageView';
import { appointmentTypes } from '../constants';
import { APPOINTMENT_ERROR } from '../constants/appointment';
import SignedInAs from '../components/SignedInAs';
import NewCustomTitle from '../components/NewCustomTitle';
import AnimatedRoute from '../components/AnimatedRoute';

const AppointmentOverview = (props) => {
  const history = useHistory();
  const [, setAppointment] = useContext(AppointmentContext);
  const dispatch = useDispatch();

  //Local storage variables
  const reservedAppointment = localStorageGet('reservedAppointment');
  const { onboardingAppointmentStatus } = localStorageGet(
    'onboardingAppointmentStatus'
  );
  const [onboardingStatus] = useState(onboardingAppointmentStatus);
  const { appointmentType } = localStorageGet('appointmentType');
  const {
    provider_id,
    to_date_time,
    from_date_time,
    patient_cognito_id,
    appointment_type_id,
    utm,
  } = reservedAppointment;

  //url variables user + appointment informaiton -- used for the native flow
  const native = getUrlParameter('platform', history) === 'native';
  const { patientProfile } = usePatientProfile(native);
  const userIdFromWebview = getUrlParameter('uid', history);
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const appointmentIdFromWebViewToReschedule = getUrlParameter(
    'appointmentId',
    history
  );
  const isRescheduleingFromWebView =
    getUrlParameter('reschedule', history) === 'true';

  const {
    setIsFetching,
    setShowBanner,
    trackOnLoadedScreenEvent,
    ApptOverviewPageSignOutBtnPressed: trackPageSignOutBtnPressed,
  } = props;

  useBookApptFlowSegmentTrackerOnPageView(
    appointmentType,
    trackOnLoadedScreenEvent
  );

  async function processAppointment() {
    try {
      if (native) {
        return processAppointmentNative();
      }

      //block for the cashpay users - does not apply to optum
      // it is here to ensure the user is successfully subscribed to stripe
      if (patientProfile.subscription_status !== 'Optum') {
        const userSubscriptionStatus = await API.get(
          'oshiAPI',
          `/users/subscribed?email=${encodeURIComponent(patientProfile.email)}`
        );

        //Why are we directing user back to book page if their status is unsubscribed?
        // we are directing the user back to the book page for them to go through the flow again in case the subscription was not successful on the first try
        if (!userSubscriptionStatus.subscribed) {
          // TODO: remove this when not doing cashpay
          // we shouldn't hit this case tho since users
          // really can't go through cashpay
          history.replace('first-appointment-book');
          setAnimationDirection('unmount');
          return setIsFetching(false);
        }
      }

      // check to see if we have the details for
      // an appointment and we then reserve it  else
      // send them back to the cal
      if (reservedAppointment) {
        await API.post(
          'oshiAPI',
          `/users/${patientProfile.cognito_id}/appointments`,
          {
            body: {
              provider_id,
              from_date_time,
              to_date_time,
              patient_cognito_id,
              appointment_type: 'onboarding',
              ...(utm
                ? {
                    utm,
                  }
                : {}),
            },
          }
        );

        triggerSuccessfullBook();
        return setIsFetching(false);
      }
      history.replace('first-appointment-book');
      return setIsFetching(false);
    } catch (error) {
      // trigger error booking onboarding appointment
      // and send them to the first appointment book page
      handleErrorAndSendToBooking();
    }
  }

  function triggerSuccessfullBook() {
    // clear context
    setAppointment();
  }

  const processAppointmentNative = async () => {
    try {
      const nativePayload = {
        provider_id,
        from_date_time,
        to_date_time,
        patient_cognito_id: userIdFromWebview,
      };
      appointmentType === ONBOARDING_APPT_TYPE
        ? (nativePayload.appointment_type = ONBOARDING_APPT_TYPE)
        : (nativePayload.appointment_type_id = appointment_type_id);

      if (isRescheduleingFromWebView) {
        // we want to add from_id when in the "rescheduleing flow"
        nativePayload.from_id = appointmentIdFromWebViewToReschedule;
      }

      // creates new appointment
      await API.post('oshiAPI', `/users/${userIdFromWebview}/appointments`, {
        body: nativePayload,
      });

      if (isRescheduleingFromWebView) {
        //change status of the appointment to reschedule if the appoitnmentn already exists
        await API.put(
          'oshiAPI',
          `/appointments/${appointmentIdFromWebViewToReschedule}`,
          {
            body: {
              appointment_status: appointmentStatuses.RESCHEDULED,
            },
          }
        );
      }
      triggerSuccessfullBook();
      return setIsFetching(false);
    } catch (error) {
      handleErrorAndSendToBooking();
    }
  };

  const handleErrorAndSendToBooking = () => {
    setShowBanner({
      sentiment: 'negative',
      text: `We're sorry, this time is no longer available. Please select a different appointment time.`,
    });
    dispatch({
      type: appointmentTypes.APPOINTMENT_ERROR,
      payload: APPOINTMENT_ERROR.TIMESLOT_TAKEN,
    });
    history.replace(`first-appointment-book${history.location.search}`);
    setAnimationDirection('unmount');
  };

  const getTitleCmp = (title, subtitle) => {
    const titleCmp = <MainHeadline>{title}</MainHeadline>;
    return (
      <NewCustomTitle
        title={titleCmp}
        subHeader={subtitle}
        setShowBanner={null}
        hasZeroCostPartner={false}
        isLastPage
      />
    );
  };

  useEffect(() => {
    return () => {
      setAnimationDirection('unmount');
    };
  }, []);

  useEffect(
    () => {
      // eslint-disable-next-line no-undef
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

      if (onboardingStatus === 'none' || Boolean(onboardingStatus) === false) {
        if (patientProfile) {
          setIsFetching(true);
          processAppointment();
        } else if (userIdFromWebview) {
          setIsFetching(true);
          processAppointmentNative();
        } else {
          setIsFetching(false);
        }
      } else {
        setIsFetching(false);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [onboardingStatus, patientProfile]
  );

  return (
    <AnimatedRoute
      title={getTitleCmp(
        'Your Appointment is booked!',
        `We've just sent you an email with the appointment details.`
      )}
      animationDirection={animationDirection}
      showProgressBar={false}
      setAnimationDirection={setAnimationDirection}
    >
      <PageContentContainer>
        <DownloadWrapper>
          <DownloadTextContainer>
            <DownloadText>
              Your next step is to
              <wbr /> download the Oshi app:
            </DownloadText>
          </DownloadTextContainer>
          <OshiDownloadDisplay />
        </DownloadWrapper>
        <VideoPlayerContainer>
          <VideoPlayer
            src='https://player.vimeo.com/video/736631362?h=726dda25e2&color=FFB59D&title=0&byline=0'
            frameborder='0'
            allow='autoplay; fullscreen;'
            allowfullscreen
          />
        </VideoPlayerContainer>
        <SignedInAs
          signOutEvent={trackPageSignOutBtnPressed}
          styles={{ flex: 1, alignItems: 'flex-end' }}
        />
      </PageContentContainer>
    </AnimatedRoute>
  );
};

const PageContentContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
`;

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media only screen and (min-width: 769px) {
    width: 636px;
  }
`;
const VideoPlayerContainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  @media only screen and (min-width: 769px) {
    width: 636px;
  }
`;

const VideoPlayer = styled.iframe`
  aspect-ratio: 16 / 9;
  width: 100vw;
  @media only screen and (min-width: 769px) {
    width: 80vw;
  }
`;
const DownloadTextContainer = styled.div`
  line-height: 130%;
  color: ${palette.navy};
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 16px;
  align-items: center;
  @media only screen and (min-width: 769px) {
    align-self: stretch;
  }
`;
const DownloadText = styled.h1`
  font-size: 28px;
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 700;
  line-height: 37px;
  @media only screen and (min-width: 769px) {
    font-size: 56px;
    line-height: 72px;
  }
`;

const MainHeadline = styled.h1`
  font-family: Source Serif Pro;
  font-style: normal;
  font-weight: 700;
  color: ${palette.white};
  font-size: 24px;
  line-height: 31.2px;
  @media only screen and (min-width: 769px) {
    font-size: 32px;
    line-height: 41.6px;
    color: ${palette.navy500};
  }
`;

export default connect(null, {
  AppointmentOverviewNote,
  AppointmentOverviewNoteSent,
  AppointmentOverviewNoNoteSent,
  AppointmentOverviewCoachBio,
  setIsFetching,
  TrackMembershipConfirmation,
  TrackAppointmentTaken,
  TrackAppointmentTakenSheduled,
  setShowBanner,
  ApptOverviewPageSignOutBtnPressed,
  trackBookAnotherAppointmentEvent,
  trackBackToAppointmentsEvent,
  trackOnLoadedScreenEvent,
})(AppointmentOverview);
