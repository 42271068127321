import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { palette } from '../theme/palette';

function OshiProgressBar({ progressBarList }) {
  const containerRef = React.useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const { currentProgress, totalProgressSteps } = useSelector((store) => ({
    currentProgress: store.uiReducer?.currentProgress,
    totalProgressSteps: store.uiReducer?.totalProgressSteps,
  }));
  const progressBarMapCb = React.useCallback(
    (route) => (
      <ProgressSlot
        key={`route-${route.step}`}
        size={containerWidth / totalProgressSteps}
        active={route.step <= currentProgress}
        space={route.step !== totalProgressSteps - 1}
      />
    ),
    [currentProgress, totalProgressSteps, containerWidth]
  );

  React.useEffect(() => {
    setContainerWidth(containerRef.current.offsetWidth);
  }, []);

  return (
    <Container>
      <ProgressContainer ref={containerRef}>
        {containerWidth > 0 && progressBarList.map(progressBarMapCb)}
      </ProgressContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6px;
  width: 269px;
  @media only screen and (min-width: 769px) {
    width: 500px;
  }
`;

const ProgressSlot = styled.div`
  width: ${(props) => props.size - 3}px;
  margin-right: ${(props) => (props.space ? 3 : 0)}px;
  background-color: ${(props) =>
    props.active ? palette.turquoise500 : palette.tan200};
  height: 6px;
  border-radius: 90px;
  @media only screen and (min-width: 769px) {
    width: ${(props) => props.size - 8}px;
    margin-right: ${(props) => (props.space ? 8 : 0)}px;
  }
`;

export default React.memo(OshiProgressBar);
