import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { setShowBanner } from '../actions';
import { palette } from '../theme/palette';

function OshiBanner({ showBanner, setShowBanner, styles }) {
  if (!showBanner) return null;

  function getIcon() {
    // eslint-disable-next-line
    switch (showBanner.sentiment) {
      case 'negative':
        return (
          <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M11.25 11.3125C11.625 10.9375 12.3125 10.9375 12.6875 11.3125L16 14.625L19.25 11.3125C19.625 10.9375 20.3125 10.9375 20.6875 11.3125C21.0625 11.6875 21.0625 12.375 20.6875 12.75L17.375 16L20.6875 19.3125C21.0625 19.6875 21.0625 20.375 20.6875 20.75C20.3125 21.125 19.625 21.125 19.25 20.75L16 17.4375L12.6875 20.75C12.3125 21.125 11.625 21.125 11.25 20.75C10.875 20.375 10.875 19.6875 11.25 19.3125L14.5625 16L11.25 12.75C10.875 12.375 10.875 11.6875 11.25 11.3125ZM32 16C32 24.875 24.8125 32 16 32C7.125 32 0 24.875 0 16C0 7.1875 7.125 0 16 0C24.8125 0 32 7.1875 32 16ZM16 2C8.25 2 2 8.3125 2 16C2 23.75 8.25 30 16 30C23.6875 30 30 23.75 30 16C30 8.3125 23.6875 2 16 2Z'
              fill='#CA3103'
            />
          </svg>
        );
      case 'positive':
        return (
          <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M14.6875 20.75C14.3125 21.125 13.625 21.125 13.25 20.75L9.25 16.75C8.875 16.375 8.875 15.6875 9.25 15.3125C9.625 14.9375 10.3125 14.9375 10.6875 15.3125L14 18.625L21.25 11.3125C21.625 10.9375 22.3125 10.9375 22.6875 11.3125C23.0625 11.6875 23.0625 12.375 22.6875 12.75L14.6875 20.75ZM32 16C32 24.875 24.8125 32 16 32C7.125 32 0 24.875 0 16C0 7.1875 7.125 0 16 0C24.8125 0 32 7.1875 32 16ZM16 2C8.25 2 2 8.3125 2 16C2 23.75 8.25 30 16 30C23.6875 30 30 23.75 30 16C30 8.3125 23.6875 2 16 2Z'
              fill='#3DD6D0'
            />
          </svg>
        );
    }
    return null;
  }
  const getColors = () => {
    switch (showBanner.sentiment) {
      case 'negative':
        return [palette.error200, palette.error50, palette.error];
      case 'positive':
        return [
          palette.turquoise500,
          palette.turquoise50,
          palette.turquoise800,
        ];
      case 'information':
        return [palette.navy500, palette.navy500, palette.white];
      default:
        break;
    }
    return ['green', 'white', 'red'];
  };
  const bannerColors = getColors();

  return (
    <Container
      borderColor={bannerColors[0]}
      background={bannerColors[1]}
      style={styles}
      height={showBanner.sentiment === 'information' ? 'auto' : `68px`}
    >
      <InnerContainer>
        {showBanner.sentiment !== 'information' && (
          <IconContaienr>
            <Pointer onClick={() => setShowBanner(false)}>{getIcon()}</Pointer>
          </IconContaienr>
        )}
        <TextContainer>
          {typeof showBanner.text === 'string' ? (
            <Text color={bannerColors[2]}>{showBanner.text}</Text>
          ) : (
            showBanner.text
          )}
        </TextContainer>
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  height: ${(props) => props.height};

  width: 100vw;

  /* error / error-50 */

  background: ${(props) => props.background || 'red'};
  /* error / error-200 */

  border-width: 1px 0px;
  border-style: solid;
  border-color: ${(props) => props.borderColor || 'black'};
  box-sizing: border-box;
  padding: 8px 16px;
  @media only screen and (min-width: 1136px) {
    width: 100%;
  }
`;

const InnerContainer = styled.div`
  max-width: 500px;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

const Pointer = styled.div`
  cursor: pointer;
`;

const IconContaienr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 16px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Text = styled.span`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  /* or 19px */

  letter-spacing: 0.04em;
  font-feature-settings: 'liga' off;

  /* error / error-500 */

  color: ${(props) => props.color || 'black'};
`;

const mapStateToProps = ({ uiReducer }) => ({
  showBanner: uiReducer.showBanner,
});

export default connect(mapStateToProps, { setShowBanner })(OshiBanner);
