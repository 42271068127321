/* eslint-disable no-use-before-define */
import React, {
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { Auth } from 'aws-amplify';
import ReactGA from 'react-ga';
import { datadogRum } from '@datadog/browser-rum';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import '../assets/styles/calendar.css';
import { AppointmentContext } from '../utils/context';
import next from '../img/chevron_right.svg';
import underline from '../img/underline.svg';
import TimePicker from '../components/TimePicker';
import OshiCalendar from '../components/OshiCalendar';
import OshiNextButton from '../components/OshiNextButton';
import Button from '../components/Button';
import { localStorageGet, localStorageSave } from '../utils/localStorageHelper';
import usePatientProfile from '../hooks/usePatientProfile';
import MembershipTransition from './MembershipTransition';
import {
  AppointmentCalendarInteraction,
  AppointmentBookTimeScroll,
  AppointmentBookTimeSelect,
  AppointmentBookBooked,
  AppointmentBookGoBack,
  NoAppointmentsAvailable,
  LessThanTenAppointmentsAvalable,
  identifyPatientSegment,
  userSubmitEvent,
  trackOnLoadedScreenEvent,
  TrackToStripeCheckoutSession,
  CalendarPageSignOutBtnPressed,
} from '../actions/segment';

import { setIsFetching, setShowBanner } from '../actions';
import getUrlParameter from '../utils/getUrlParameter';
import useComponentFocus from '../hooks/useComponentFocus';
import { stripePromise } from '../App';

import {
  INSURED_FLOW,
  ZERO_COST_PARTNERS,
} from '../constants/subscriptionStatus';
import { CURRENT_PARTNERS } from '../constants/subscriptionStatus';
import {
  REFERRAL_APPT_TYPE,
  ONBOARDING_APPT_TYPE,
  FOLLOW_UP_APPT_TYPE,
} from '../constants/appointment';
import {
  ONE_WEEK,
  TWO_WEEK,
  ONE_WEEK_MS,
  SIX_WEEK_MS,
  FORTYEIGHT_HOURS,
} from '../constants/calendar';
import { stateAbbreviations } from '../constants/stateAbbreviations';
import { palette } from '../theme/palette';
import useSegmentTracker from '../hooks/useSegmentTracker';
import useAvailableAppointments from '../hooks/useAvailableAppointments';
import SignedInAs from '../components/SignedInAs';
import { ROUTES } from '../constants/routes';
import AnimatedRoute from '../components/AnimatedRoute';
import NewCustomTitle from '../components/NewCustomTitle';
import useWindowSize from '../hooks/useWindowSize';

const formatDate = (date) => {
  const days = [
    'Sunday,',
    'Monday,',
    'Tuesday,',
    'Wednesday,',
    'Thursday,',
    'Friday,',
    'Saturday,',
  ];
  const dateObject = new Date(date);
  const day = dateObject.getDay();
  const dayArr = String(date).split(' ').splice(0, 4);
  dayArr.splice(0, 1, days[day]);
  const joinedDate = dayArr.join(' ');
  return joinedDate;
};

//todo: remove once all active users are on updated version of app
function getAppointmentTypeBC(
  appointmentTypeFromWebview = null,
  providerIdFromWebview
) {
  if (appointmentTypeFromWebview) {
    // means you are in new version
    return appointmentTypeFromWebview;
  } else {
    // means you are in old version
    // old version doesn't handle referrals
    if (providerIdFromWebview) {
      return FOLLOW_UP_APPT_TYPE;
    } else {
      return ONBOARDING_APPT_TYPE;
    }
  }
}

const AppointmentCalendar = ({
  setShowBanner,
  AppointmentCalendarInteraction,
  AppointmentBookTimeScroll,
  AppointmentBookTimeSelect,
  AppointmentBookBooked,
  NoAppointmentsAvailable,
  LessThanTenAppointmentsAvalable,
  setIsFetching,
  identifyPatientSegment,
  userSubmitEvent,
  trackOnLoadedScreenEvent,
  showBanner,
  CalendarPageSignOutBtnPressed,
  availableAppointments,
  lastLoadedDate,
}) => {
  const [showTransionToBillinScene, setShowTransionToBillinScene] = useState(
    false
  );
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const [headlineRef, setHeadlineFocus] = useComponentFocus();
  const [bookApptRef, setBookApptBtnFocus] = useComponentFocus();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [selected, setSelected] = useState(new Date());
  const [firstAvailableDate, setFirstAvailableDate] = useState(null);
  const { screenSize } = useWindowSize();
  const [selectedWeek, setSelectedWeek] = useState();
  const [appointmentFullDate, setAppointmentFullDate] = useState(new Date());
  const [appointmentDate, setAppointmentDate] = useState(
    formatDate(new Date())
  );
  const [appointmentTime, setAppointmentTime] = useState(false);
  const [appointmentTimeNode, setAppointmentTimeNode] = useState('');
  const [appointment, setAppointment] = useContext(AppointmentContext);
  const [
    sortedAppointmentForSelectedDate,
    setSortedAppointmentForSelectedDate,
  ] = useState([]);
  const [ButtonDisableState, setButtonDisableState] = useState(true);
  const [mappedAppointments, setMappedAppointments] = useState({});
  const history = useHistory();
  const { partner } = localStorageGet('partner');
  const { selectedPartner } = localStorageGet('selectedPartner');
  const partnerName = selectedPartner || partner;
  const platform = getUrlParameter('platform', history);
  const native = platform === 'native';
  const isReschedulingFromWebView =
    getUrlParameter('reschedule', history) === 'true';
  const providerIdFromWebview = getUrlParameter('pid', history);
  const cognitoIdFromWebview = getUrlParameter('uid', history);
  const appointmentTypeIdFromWebview = getUrlParameter('appt_type', history);
  const appointmentTypeFromWebview = getAppointmentTypeBC(
    getUrlParameter('type', history),
    providerIdFromWebview
  );
  const { trackSegmentFn: trackOnSubmit } = useSegmentTracker(
    appointmentTypeFromWebview,
    userSubmitEvent
  );
  const { trackSegmentFn: trackOnLoad } = useSegmentTracker(
    appointmentTypeFromWebview,
    trackOnLoadedScreenEvent
  );
  const { patientProfile: patient } = usePatientProfile(native);
  const [availableDates, setAvailableDates] = useState([]);
  const { subscriptionStatus } = localStorageGet('subscriptionStatus');
  const subscriptionStatusFromWebview = getUrlParameter(
    'subscription_status',
    history
  )
    .toString()
    .toLowerCase();
  const formatAppointmentTimeslotDate = (date) =>
    moment(date).format('M/D/YYYY');
  const formatFirstAvailableDate = (date) => moment(date).format('MMMM D');
  const sortAppointmentTimeslotsInAsc = (timeslots) =>
    timeslots.sort((a, b) => a.available_from - b.available_from);
  const stripeSession = useRef();
  const signinUTMParams = localStorageGet('signinUTMParams');

  // need to add some margin when in webview
  const btnStyles = {
    width: '100%',
    color: palette.melon800,
    fontWeight: 600,
  };

  const nextAppoinmentButtonStyle = {
    backgroundColor: palette.white,
    color: palette.navy,
    border: `2px solid ${palette.turquoise200}`,
  };

  const didComeFromAppointmentOverview =
    getUrlParameter('from_location', history) === 'appointmentOverview';

  useEffect(() => {
    return () => {
      setShowBanner(false);
    };
    // eslint-disable-next-line
  }, []);

  const patientIdentifiedThroughSegment = localStorageGet(
    'patientIdentifiedThroughSegment'
  );
  const salesForceId = getUrlParameter('sfId', history);

  useLayoutEffect(() => {
    // Fetch flag is true to display loading screen before rendering screen components
    setIsFetching(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const availableApptsList = Object.values(mappedAppointments);
    appointmentTypeFromWebview &&
      localStorageSave('appointmentType', {
        appointmentType: appointmentTypeFromWebview,
      });
    if (availableApptsList[0]?.length > 0) {
      if (appointmentTypeFromWebview === REFERRAL_APPT_TYPE) {
        trackOnLoad(availableApptsList[0][0].provider_role);
      } else {
        trackOnLoad();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTypeFromWebview, mappedAppointments]);

  useEffect(() => {
    if (
      native && // if native
      salesForceId &&
      !patientIdentifiedThroughSegment && // if we haven't identified this patient
      salesForceId !== patientIdentifiedThroughSegment // if id's don't match with what is tored
    ) {
      identifyPatientSegment(salesForceId, {});
      datadogRum.setUser({
        id: salesForceId,
      });
      localStorageSave('patientIdentifiedThroughSegment', salesForceId);
    }
    // eslint-disable-next-line
  }, [native, salesForceId, patientIdentifiedThroughSegment]);

  async function sendUserToCheckout() {
    setIsFetching(true);
    const stripe = await stripePromise;

    const result = await stripe.redirectToCheckout({
      sessionId: stripeSession.current.id,
    });

    TrackToStripeCheckoutSession();

    if (result.error) {
      console.log('result here', result);
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  }

  useEffect(() => {
    const loadThreshold = new Date(selectedWeek);
    loadThreshold.setDate(loadThreshold.getDate() + TWO_WEEK);

    const weekSelectLimit =
      new Date(new Date().toLocaleDateString()).getTime() + SIX_WEEK_MS;

    if (lastLoadedDate < loadThreshold.getTime()) {
      const to =
        loadThreshold.getTime() - lastLoadedDate < ONE_WEEK_MS
          ? loadThreshold.getTime() + ONE_WEEK_MS
          : loadThreshold.getTime();

      if (to < weekSelectLimit) {
        getAvailableAppointments({ from: lastLoadedDate, to });
      } else if (lastLoadedDate < weekSelectLimit) {
        getAvailableAppointments({ from: lastLoadedDate, to: weekSelectLimit });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek, lastLoadedDate]);

  useEffect(() => {
    const endOfWeek = new Date(selectedWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    // Make sure the whole week is loaded before firing segment events
    if (lastLoadedDate > endOfWeek.getTime()) {
      const weekOf = new Date(selectedWeek);
      let appointmentCountForWeek = 0;

      for (var i = 0; i < 7; i++) {
        const day = formatAppointmentTimeslotDate(weekOf);
        if (Object.keys(mappedAppointments).includes(day)) {
          appointmentCountForWeek += mappedAppointments[day].length;
        }
        weekOf.setDate(weekOf.getDate() + 1);
      }

      const userState = Object.keys(stateAbbreviations).find(
        (key) => stateAbbreviations[key] === patient.state
      );
      if (appointmentCountForWeek === 0) {
        NoAppointmentsAvailable({
          date: formatAppointmentTimeslotDate(selectedWeek),
          userState,
        });
      } else if (appointmentCountForWeek < 10) {
        LessThanTenAppointmentsAvalable({
          date: formatAppointmentTimeslotDate(selectedWeek),
          userState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek]);

  const getQueryStringParams = () => {
    const queryStringParameters = {};
    if (native) {
      queryStringParameters.cognito_id = cognitoIdFromWebview;

      if (appointmentTypeFromWebview === ONBOARDING_APPT_TYPE) {
        queryStringParameters.appointment_type = ONBOARDING_APPT_TYPE;
      } else if (appointmentTypeFromWebview === REFERRAL_APPT_TYPE) {
        queryStringParameters.appointment_type_id = appointmentTypeIdFromWebview;
      } else {
        queryStringParameters.appointment_type_id = appointmentTypeIdFromWebview;
        queryStringParameters.subscriptionStatus = subscriptionStatusFromWebview;
      }
      localStorageSave('subscriptionStatus', {
        subscriptionStatus: subscriptionStatusFromWebview,
      });
    } else {
      queryStringParameters.appointment_type = ONBOARDING_APPT_TYPE;
      queryStringParameters.cognito_id = patient.cognito_id;
    }
    return queryStringParameters;
  };

  const onFetchComplete = () => {
    setIsFetching(false);
  };

  const { getAvailableAppointments } = useAvailableAppointments({
    setIsFetching,
    native,
    appointmentTypeFromWebview,
    isReschedulingFromWebView,
    queryStringParameters: getQueryStringParams(),
    providerIdFromWebview,
    onFetchComplete,
  });

  useLayoutEffect(() => {
    fetchAvailability(availableAppointments);
    const mappedAvailableTimes = mapAvailableTimes(availableAppointments);
    const firstAvailableDate = getFirstAvailableDate(mappedAvailableTimes);
    const firstAvailableDateFormatted = formatAppointmentTimeslotDate(
      firstAvailableDate
    );

    if (!selectedWeek && Object.keys(mappedAvailableTimes).length > 0) {
      const firstAvailableDate = getFirstAvailableDate(mappedAvailableTimes);
      setSelected(firstAvailableDate);
      setFirstAvailableDate(formatFirstAvailableDate(firstAvailableDate));
      setSelectedWeek(getFirstAvailableDate(mappedAvailableTimes));
      setSortedAppointmentForSelectedDate(
        sortAppointmentTimeslotsInAsc(
          mappedAvailableTimes[firstAvailableDateFormatted]
        )
      );
      setAppointmentDate(formatDate(firstAvailableDate));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableAppointments]);

  useEffect(() => {
    headlineRef.current && setHeadlineFocus();
    // eslint-disable-next-line
  }, [setHeadlineFocus]);

  useEffect(() => {
    appointmentTime &&
      appointmentTimeNode >= 0 &&
      bookApptRef.current &&
      setBookApptBtnFocus();
    // eslint-disable-next-line
  }, [appointmentTime, appointmentTimeNode, setBookApptBtnFocus]);

  const mapAvailableTimes = (fetchedAppointments) => {
    const mappedAvailableTimes = {};

    // loop over available appointments array and map the times
    if (Object.keys(fetchedAppointments).length > 0) {
      for (const date of Object.values(fetchedAppointments)) {
        const key = formatAppointmentTimeslotDate(date.available_from);

        if (mappedAvailableTimes[key]) {
          mappedAvailableTimes[key].push(date);
        } else {
          mappedAvailableTimes[key] = [date];
        }
      }
    }

    return mappedAvailableTimes;
  };

  const getFirstAvailableDate = (mappedAvailableTimes) => {
    return new Date(
      Object.keys(mappedAvailableTimes).sort((a, b) => {
        return Date.parse(a) - Date.parse(b);
      })[0]
    );
  };

  const fetchAvailability = async (fetchedAppointments) => {
    const mappedAvailableTimes = mapAvailableTimes(fetchedAppointments);

    setAvailableDates(Object.keys(mappedAvailableTimes));
    setMappedAppointments(mappedAvailableTimes);
  };

  const onDateChange = (date) => {
    const formattedDate = formatAppointmentTimeslotDate(date);

    const sortedDailyAppointments = sortAppointmentTimeslotsInAsc(
      mappedAppointments[formattedDate]
    );
    setSortedAppointmentForSelectedDate(sortedDailyAppointments);

    isFirstRender && setIsFirstRender(false);
    setAppointmentFullDate(date);
    setAppointmentDate(formatDate(date));
    setSelected(date);
    AppointmentCalendarInteraction();

    setAppointmentTime(null);
    setAppointmentTimeNode(-1);
  };

  const onTimeSelect = (time, node) => {
    setAppointmentTime(time);
    setAppointmentTimeNode(node);
    AppointmentBookTimeSelect();
    if (time.length > 0) {
      if (ButtonDisableState) {
        setButtonDisableState(false);
      }
    } else {
      setButtonDisableState(true);
    }
  };

  const selectFirstAvailableAppointmentInWeek = (week) => {
    let noAvailableAppts = true;
    for (var i = 0; i < 7; i++) {
      const date = new Date(week);
      date.setDate(date.getDate() + i);

      const formattedDate = `${
        date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`;
      if (availableDates.includes(formattedDate)) {
        onDateChange(date);
        noAvailableAppts = false;
        break;
      }
    }

    if (noAvailableAppts) {
      setSelected(null);
      setSortedAppointmentForSelectedDate([]);
    }
  };

  const selectNextWeek = () => {
    const selected = new Date(selectedWeek);
    const week = new Date(selected.setDate(selectedWeek.getDate() + 7));

    if (
      week.getTime() - new Date(new Date().toLocaleDateString()).getTime() <
        SIX_WEEK_MS &&
      new Date(selectedWeek).getTime() < lastLoadedDate
    ) {
      setSelectedWeek(week);
      selectFirstAvailableAppointmentInWeek(week);
    }
  };

  const selectPrevWeek = () => {
    const week = new Date(selectedWeek);
    const prevWeek = new Date(week.setDate(week.getDate() - 7));
    const earliestDate = new Date();
    earliestDate.setTime(earliestDate.getTime() + FORTYEIGHT_HOURS);

    if (prevWeek.getTime() < earliestDate.getTime()) {
      setSelectedWeek(earliestDate);
      prevWeek.setTime(earliestDate.getTime());
    } else {
      setSelectedWeek(prevWeek);
    }

    selectFirstAvailableAppointmentInWeek(prevWeek);
  };

  const shouldShowNextAvailableButton = () => {
    const selected = new Date(selectedWeek);
    const week = new Date(selected.setDate(selectedWeek.getDate() + 7));

    if (
      week.getTime() - new Date(new Date().toLocaleDateString()).getTime() >=
      SIX_WEEK_MS
    )
      return false;
    if (Object.keys(availableAppointments).length === 0) return false;

    const lastAvailableDate = Object.keys(
      availableAppointments
    ).reduce((a, b) => (a > b ? a : b));
    return (
      sortedAppointmentForSelectedDate.length === 0 &&
      selectedWeek < lastAvailableDate
    );
  };

  const jumpToNext = () => {
    const SelectedWeekDate = new Date(selectedWeek);

    for (const date of availableDates) {
      const week = new Date(date);
      const weekEnd = new Date(week);
      weekEnd.setDate(weekEnd.getDate() + ONE_WEEK);
      if (week.getTime() > SelectedWeekDate) {
        setSelectedWeek(week);
        selectFirstAvailableAppointmentInWeek(week);
        break;
      }
    }
  };

  const onBook = async () => {
    setAppointment({
      Appointment: true,
      AppointmentTime: appointmentTime,
      AppointmentDate: appointmentDate,
      AppointmentTimeNode: appointmentTimeNode,
      AppointmentFullDate: appointmentFullDate,
    });
    if (subscriptionStatusFromWebview !== 'false') {
      AppointmentBookBooked({
        userStatus: subscriptionStatusFromWebview,
      });
    } else {
      AppointmentBookBooked({
        userStatus: patient.subscription_status,
      });
    }

    if (selectedPartner === CURRENT_PARTNERS.UHC) {
      ReactGA.event({
        category: 'all',
        action: 'First Appt Confirm - Confirmed',
        label: 'event',
      });
    }

    setIsFetching(true);
    const cognitoUser = native ? null : await Auth.currentAuthenticatedUser();

    const createAppointmentBody = {
      provider_id: appointmentTime.provider_id,
      from_date_time: appointmentTime.available_from,
      to_date_time: appointmentTime.available_to,
      provider_role: appointmentTime.provider_role,
      appointment_type_id:
        native && appointmentTypeFromWebview !== ONBOARDING_APPT_TYPE
          ? appointmentTypeIdFromWebview
          : ONBOARDING_APPT_TYPE,
      price_id: appointmentTime.stripe_id,
      patient_cognito_id: cognitoUser?.attributes?.sub,
      provider_name: appointmentTime.provider_name,
      appointmentDate: appointment.AppointmentDate,
      appointmentDuration: appointmentTime.duration,
      ...(signinUTMParams ? { utm: signinUTMParams } : {}),
    };

    trackOnSubmit(
      appointmentTypeFromWebview === REFERRAL_APPT_TYPE &&
        appointmentTime.provider_role
        ? appointmentTime.provider_role
        : null
    );
    localStorageSave('reservedAppointment', createAppointmentBody);
    setAnimationDirection('unmount');
    setIsFetching(false);

    if (native) {
      history.push(`/first-appointment-overview${history.location.search}`);
    } else if (didComeFromAppointmentOverview) {
      history.push(
        '/first-appointment-overview?from_location=appointmentOverview'
      );
    } else {
      if (INSURED_FLOW.includes(subscriptionStatus)) {
        history.push(`/first-appointment-overview`);
      } else {
        setShowTransionToBillinScene(true);
      }
    }
  };

  if (showTransionToBillinScene) {
    return <MembershipTransition animationFinishAction={sendUserToCheckout} />;
  }

  const renderZeroCostHeader = () => (
    <ZeroCostContainer>
      <ZeroCostText>$0 Cost</ZeroCostText>
      <ZeroCostUnderline src={underline} />
    </ZeroCostContainer>
  );

  const getTitleCmp = (titleSet, subHeader) => {
    const [mainTitle, bottomMainTitle] = titleSet;
    const titleCmp = screenSize.isMobile ? (
      <>
        <CopyTitle id='copyTitle'>{mainTitle}</CopyTitle>
        <CopyTitle>
          {ZERO_COST_PARTNERS.includes(partnerName) ? (
            <ZeroCostHeader>
              <div>at&nbsp;</div>
              {renderZeroCostHeader()}
              <div>&nbsp;to you</div>
            </ZeroCostHeader>
          ) : (
            bottomMainTitle
          )}
        </CopyTitle>
      </>
    ) : (
      'Choose a Time'
    );
    const subHeaderCmp = screenSize.isMobile ? (
      subHeader
    ) : (
      <>
        {firstAvailableDate ? (
          <SubHeadline>
            The first available appointment with a <strong>GI Provider</strong>{' '}
            is <strong>{firstAvailableDate}</strong>
          </SubHeadline>
        ) : null}
      </>
    );
    return (
      <NewCustomTitle
        setShowBanner={null}
        hasZeroCostPartner={false}
        title={titleCmp}
        subHeader={subHeaderCmp}
      />
    );
  };

  return (
    <AnimatedRoute
      nextRoute={ROUTES.FIRST_APPT_OVERVIEW}
      title={getTitleCmp(
        ['Book your appointment', '— included with your plan'],
        `Choose a date and time that works for you.`
      )}
      animationDirection={animationDirection}
      showProgressBar={false}
      setAnimationDirection={setAnimationDirection}
    >
      <FlexContainer>
        {!!selectedWeek && (
          <ContentContainer>
            <CalendarContainer testId='appointment-book-calendar-container'>
              <OshiCalendar
                lastLoadedDate={lastLoadedDate}
                selectedWeek={selectedWeek}
                selectedDate={selected}
                onDateChange={onDateChange}
                availableDates={availableDates}
                onNextWeek={selectNextWeek}
                onPrevWeek={selectPrevWeek}
              />
            </CalendarContainer>

            <TimeWrapper>
              <TimeTitle aria-label='Times Available'>
                Times Available
              </TimeTitle>
              {shouldShowNextAvailableButton() ? (
                <NextAvailableButton
                  styles={nextAppoinmentButtonStyle}
                  onClick={jumpToNext}
                >
                  Next Available Appointment
                  <NextAvailableButtonIcon src={next} />
                </NextAvailableButton>
              ) : (
                <TimePicker
                  testId='appointment-book-time-component'
                  labels={sortedAppointmentForSelectedDate}
                  initialSelect={appointment.AppointmentTimeNode}
                  onSelect={onTimeSelect}
                  onScrollSegment={AppointmentBookTimeScroll}
                  lastLoadedDate={lastLoadedDate}
                  selectedWeek={selectedWeek}
                />
              )}
            </TimeWrapper>
          </ContentContainer>
        )}
        <BottomContainer>
          <OshiNextButton
            ref={bookApptRef}
            onClick={onBook}
            disabled={!appointmentTime}
            styles={btnStyles}
            buttonTitle='Book Now'
          >
            <SignedInAs
              additionalText={`It's easy to cancel or reschedule in the Oshi Health mobile app.`}
              signOutEvent={CalendarPageSignOutBtnPressed}
              styles={{
                flex: '0',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            />
          </OshiNextButton>
        </BottomContainer>
      </FlexContainer>
    </AnimatedRoute>
  );
};

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 550px;
`;
const CopyTitle = styled.div`
  text-align: left;
  font-size: 24px;
  font-family: 'Source Serif Pro', serif;
  line-height: 31.2px;
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 8px;
  color: ${palette.navy};
  @media only screen and (max-width: 480px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 768px) {
    color: ${palette.white};
  }
`;
const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 768px) {
    flex-direction: column;
    justify-content: space-evenly;
  }
`;
const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (min-width: 769px) {
    height: auto;
  }
  width: 100%;
`;
const TimeWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  width: 100%;
  @media only screen and (min-width: 769px) {
    justify-content: flex-start;
    border: none;
  }
`;
const TimeTitle = styled.div`
  align-self: flex-start;
  text-align: left;
  font-size: 15px;
  font-family: Usual;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.06em;
  white-space: pre-line;
  margin-bottom: 12px;
  color: ${palette.navy600};
  display: flex;
  align-items: center;
  text-align: center;
`;
const BottomContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 12px;
  @media only screen and (min-width: 769px) {
    align-items: flex-start;
  }
`;
const NextAvailableButton = styled(Button)`
  padding: 0;
  width: 100%;
`;
const NextAvailableButtonIcon = styled.img`
  padding: 0 12px;
`;
const ZeroCostHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;
const ZeroCostText = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  margin: -5px 0;
  color: ${palette.navy};
  @media only screen and (max-width: 429px) {
    margin: -9px 0;
    font-size: 24px;
  }
  @media only screen and (max-width: 768px) {
    color: ${palette.melon300};
  }
`;
const ZeroCostUnderline = styled.img`
  padding: 0 5px;
  @media only screen and (max-width: 429px) {
    width: 73px;
  }
`;
const ZeroCostContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const SubHeadline = styled.p`
  font-weight: 400;
  line-height: 150%;
  font-family: 'Usual';
  color: ${palette.white};
  font-size: 14px;
  @media only screen and (min-width: 769px) {
    color: ${palette.navy500};
    font-size: 16px;
  }
`;

const mapStateToProps = ({ uiReducer, appointmentReducer }) => ({
  isFetching: uiReducer.isFetching,
  showBanner: uiReducer.showBanner,
  availableAppointments: appointmentReducer?.availableAppointments || {},
  lastLoadedDate: appointmentReducer?.lastLoadedDate,
});
export default connect(mapStateToProps, {
  setIsFetching,
  AppointmentCalendarInteraction,
  AppointmentBookTimeScroll,
  AppointmentBookTimeSelect,
  AppointmentBookBooked,
  AppointmentBookGoBack,
  NoAppointmentsAvailable,
  LessThanTenAppointmentsAvalable,
  identifyPatientSegment,
  userSubmitEvent,
  trackOnLoadedScreenEvent,
  CalendarPageSignOutBtnPressed,
  setShowBanner,
})(AppointmentCalendar);
