import React from 'react';
import styled from 'styled-components';
import { palette } from '../theme/palette';
import next from '../img/chevron_right.svg';
import previous from '../img/chevron_left.svg';
import { DatePickerLoader } from './SkeletonLoaders';
import { MONTHS, WEEKDAYS } from '../constants/calendar';

const Day = ({ date, onClick, dayState }) => {
  const fontColor = dayState === 'selected' ? palette.white : palette.navy;

  const backgroundColor = {
    disabled: palette.coolGray50,
    selected: palette.navy,
    available: palette.turquoise50,
  };

  return (
    <DayContainer
      onClick={dayState === 'available' ? onClick : undefined}
      style={{
        backgroundColor: backgroundColor[dayState],
        cursor:
          dayState === 'available' || dayState === 'selected'
            ? 'pointer'
            : 'default',
      }}
    >
      <DayText style={{ color: fontColor }}>{WEEKDAYS[date.getDay()]}</DayText>
      <DayNumber style={{ color: fontColor }}>{date.getDate()}</DayNumber>
    </DayContainer>
  );
};

const renderDays = ({
  selectedDate,
  selectedWeek,
  onDateChange,
  availableDates,
}) => {
  var days = [];

  for (var i = 0; i < 7; i++) {
    const baseDate = new Date(selectedWeek);
    const date = new Date(baseDate.setDate(baseDate.getDate() + i));

    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;

    const dayState = () => {
      if (!availableDates.includes(formattedDate)) return 'disabled';
      return date?.getTime() === selectedDate?.getTime()
        ? 'selected'
        : 'available';
    };

    days.push(
      <Day
        date={date}
        onClick={() => onDateChange(date)}
        dayState={dayState()}
      />
    );
  }

  return days;
};

const weekLabelText = ({ selectedWeek }) => {
  const baseDate = new Date(selectedWeek);
  const endDate = new Date(baseDate.setDate(baseDate.getDate() + 7));

  return `${MONTHS[selectedWeek.getMonth()]} ${selectedWeek.getDate()} - ${
    MONTHS[endDate.getMonth()]
  } ${endDate.getDate()}`;
};

const OshiCalendar = ({
  lastLoadedDate,
  selectedWeek,
  selectedDate,
  onDateChange,
  availableDates,
  onNextWeek,
  onPrevWeek,
}) => {
  const isFirstWeek =
    selectedWeek.toString() === new Date(availableDates[0]).toString();

  return (
    <CalendarContainer>
      <WeekPickerContainer>
        <WeekPickerLabel>{weekLabelText({ selectedWeek })}</WeekPickerLabel>
        <WeekPickerButtons>
          <ButtonIcon
            src={previous}
            onClick={!isFirstWeek ? onPrevWeek : () => {}}
          />
          <ButtonIcon src={next} onClick={onNextWeek} />
        </WeekPickerButtons>
      </WeekPickerContainer>
      <DayPickerContainer>
        {new Date(selectedWeek).getTime() >= lastLoadedDate ? (
          <DatePickerLoader />
        ) : (
          renderDays({
            selectedDate,
            selectedWeek,
            onDateChange,
            availableDates,
          })
        )}
      </DayPickerContainer>
    </CalendarContainer>
  );
};

const ButtonIcon = styled.img`
  padding: 12px 16px;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DayPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const DayContainer = styled.div`
  align-items: center;
  background-color: ${palette.turquoise50};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  width: 100%;
  margin: 4px;

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  @media only screen and (min-width: 769px) {
    margin: 0 6px;
  }
`;

const DayText = styled.div`
  color: ${palette.navy};
  font-family: usual;
  font-size: 13px;
  font-weight: 300;
  margin: 3px 0;
`;

const DayNumber = styled.div`
  color: ${palette.navy};
  font-family: usual;
  font-size: 13px;
  font-weight: 600;
  margin: 3px 0;
`;

const WeekPickerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 12px;
`;

const WeekPickerLabel = styled.div`
  color: ${palette.navy};
  font-family: usual;
  font-weight: 600;
  line-height: 24px;
  font-size: 15px;
`;

const WeekPickerButtons = styled.div`
  align-self: end;
  display: flex;
  flex-direction: row;
`;

export default OshiCalendar;
