import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';

import { palette } from '../theme/palette';
import Anime from '../assets/rs-banner-anime.json';
import { HeroDocBlob, ZeroCostBlob } from '../assets/svgs';
import { ZERO_COST_PARTNERS } from '../constants/subscriptionStatus';

function RightSidedBanner({ maxWidth, isNameRoute }) {
  const splitUrl = window.location.pathname.split('/');
  const partnerName = splitUrl[2];
  const hasZeroCostPartner = ZERO_COST_PARTNERS.includes(partnerName);
  const { hideRightSidedBanner } = useSelector((store) => ({
    hideRightSidedBanner: store.uiReducer?.hideRightSidedBanner,
  }));

  if (hideRightSidedBanner) return null;

  return (
    <RightContainer style={{ maxWidth }}>
      <LottieContainer>
        <Lottie
          options={{
            animationData: Anime,
            loop: true,
            autoplay: true,
          }}
          loop
        />
      </LottieContainer>

      <BlobContainer>
        {hasZeroCostPartner && isNameRoute && <DocBlob />}
        {hasZeroCostPartner ? <CostBlob /> : null}
      </BlobContainer>
    </RightContainer>
  );
}

const RightContainer = styled.div`
  display: flex;
  width: 40%;
  max-width: 40%;
  background: ${palette.navy500};
  @media (max-width: 1135px) {
    display: none;
  }
  align-items: center;
  justify-content: center;
`;

const LottieContainer = styled.div`
  position: absolute;
  z-index: 0;
`;

const BlobContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const DocBlob = styled(HeroDocBlob)`
  position: absolute;
  z-index: 1;
`;
const CostBlob = styled(ZeroCostBlob)`
  margin-top: -172px;
  margin-left: -348px;
  z-index: 2;
`;

const mapStateToProps = ({ uiReducer }) => ({
  isNameRoute: uiReducer.isNameRoute,
});

export default connect(mapStateToProps)(RightSidedBanner);
