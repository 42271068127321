import React from 'react';

import Typography from '../atoms/Typography';
import {
  CURRENT_PARTNERS,
  PARTNERS_BY_ORDER,
} from '../constants/subscriptionStatus';
import { AetnaLogo, UHCLogo, FireflyLogo, BlueCrossLogo } from '../assets/svgs';
import { palette } from '../theme/palette';

/**
 * @function useHealthPlanPageUILogic
 * @description Custom hook to handle customized UI logic operations for new health UI page
 * These operations are contained in functions that, for instance, parses partner list data
 * to display in this UI page. Any UI logic related to this page shall be implemented here.
 * @returns {{
 *   parseList: PartnerUI[],
 * }}
 */
const useHealthPlanPageUILogic = ({ isFirstHealthPlanPartnerById }) => {
  const PARTERNS_LOGO_MAP = {
    [CURRENT_PARTNERS.AETNA]: <AetnaLogo />,
    [CURRENT_PARTNERS.UHC]: <UHCLogo />,
    [CURRENT_PARTNERS.BLUE_CROSS]: <BlueCrossLogo />,
    [CURRENT_PARTNERS.FIREFLY]: <FireflyLogo />,
  };
  const getOrderNumberByPartnerId = (partnerId) => {
    return PARTNERS_BY_ORDER.findIndex((p) => p === partnerId) + 1;
  };
  const parseList = (list) => {
    const newList = list
      ?.filter((p) => isFirstHealthPlanPartnerById(p.system_partner_id))
      ?.map((p) => {
        return {
          ...p,
          order: getOrderNumberByPartnerId(p.system_partner_id),
          children: PARTERNS_LOGO_MAP[p.system_partner_id],
        };
      });
    newList.length > 0 &&
      newList.push({
        type: 'Partner',
        order: getOrderNumberByPartnerId(CURRENT_PARTNERS.OTHER),
        system_partner_id: CURRENT_PARTNERS.OTHER,
        name: 'Other',
        children: (
          <Typography
            type='paragraph'
            styles={{
              fontWeight: '400',
              color: palette.navy500,
              textDecorationLine: 'underline',
            }}
          >
            Oshi Health is covered by my employer.
          </Typography>
        ),
        operatingStates: [],
        is_active: true,
        display_name: null,
      });
    newList.sort((a, b) => a.order - b.order);
    return newList;
  };

  return {
    parseList,
  };
};

export default useHealthPlanPageUILogic;
