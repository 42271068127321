import React from 'react';
import styled from 'styled-components';

import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';

/**
 * @function OshiSelectOption
 * @description Custom and reusable UI component to show select option component within a select component.
 * @param {string} value - Value text to link this component
 * @param {string} label - Label text to be displayed. 
 * @param {(value: string): void} onClick - UI event to handle when this component gets clicked.
 * @param {styled.CSSObject} styled - Custom CSS object style to customize this component root element. 
 * @returns {React.ReactElement}
 */
const OshiSelectOption = ({ value, label, onClick, style = {} }) => {
  return (
    <Option onClick={onClick} value={value} style={style}>
      <Typography
        type={'paragraph'}
      >
        {label}
      </Typography>
    </Option>
  );
};

const Option = styled.div`
  cursor: default;
  color: ${palette.coolGray500};
  font-weight: 400;
  padding: 8px 12px;
  &:hover {
    background-color: ${palette.turquoise50};
  }
`;

export default React.memo(OshiSelectOption);
