import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import DLiPhoneApp from '../img/AppStore.svg';
import DLAndroidApp from '../img/GooglePlay.svg';
import WebQr from '../img/web-qr.svg';
import { palette } from '../theme/palette';

const IPHONE = 'iPhone';
const ANDROID = 'Android';
const WEB = 'Web';

function OshiDownloadDisplay() {
  const [deviceType, setDeviceType] = useState(null);

  useEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      setDeviceType(ANDROID);
    } else if (/iPhone/i.test(navigator.userAgent)) {
      setDeviceType(IPHONE);
    } else {
      setDeviceType(WEB);
    }
  }, []);

  function handleStoreImageClick(device) {
    window.location.href =
      device === IPHONE
        ? 'https://apps.apple.com/us/app/oshi-health/id1518331430'
        : 'https://play.google.com/store/apps/details?id=com.oshimobile';
  }

  function getDeviceType() {
    switch (deviceType) {
      case IPHONE:
        return (
          <img
            onClick={() => handleStoreImageClick(IPHONE)}
            src={DLiPhoneApp}
            alt='Download for iPhone'
          />
        );
      case ANDROID:
        return (
          <img
            onClick={() => handleStoreImageClick(ANDROID)}
            src={DLAndroidApp}
            alt='Download for Android'
          />
        );
      case WEB:
        return (
          <DLContainer>
            <DeviceDLContainer>
              <TextContainer>
                <span>
                  Scan the code on your right with your smart phone camera to
                  download our mobile app.
                </span>
              </TextContainer>
              <DevicesContainer>
                <WebDLImages
                  onClick={() => handleStoreImageClick(IPHONE)}
                  src={DLiPhoneApp}
                  alt='Download for iPhone'
                />
                <WebDLImages
                  onClick={() => handleStoreImageClick(ANDROID)}
                  src={DLAndroidApp}
                  alt='Download for Android'
                />
              </DevicesContainer>
            </DeviceDLContainer>
            <QrContainer>
              <img style={{ height: '137px' }} src={WebQr} alt='QR code' />
            </QrContainer>
          </DLContainer>
        );
      default:
        return null;
    }
  }

  return <Container>{getDeviceType()}</Container>;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 40px;
  @media only screen and (min-width: 769px) {
    padding-bottom: 48px;
    justify-content: left;
  }
`;
const DLContainer = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
`;

const QrContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const DeviceDLContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TextContainer = styled.div`
  display: flex;
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 168%;
  /* or 24px */

  letter-spacing: 0.04em;
  font-feature-settings: 'liga' off;

  color: ${palette.coolGray};
  margin-bottom: 15px;
`;

const DevicesContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  & > img {
    margin-right: 0;
    margin-bottom: 12px;
  }
  @media only screen and (min-width: 769px) {
    flex-direction: row;
    & > img {
      margin-right: 12px;
      margin-bottom: 0;
    }
  }
`;

const WebDLImages = styled.img`
  width: calc(80% - 12px);
  cursor: pointer;
  @media only screen and (min-width: 769px) {
    width: auto;
  }
`;

export default React.memo(OshiDownloadDisplay);
