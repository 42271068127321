import { API } from 'aws-amplify';

/**
 * Custom hook that collects partner-based API endpoints to use across the app
 * @returns { { fetchList(): Partner[] } }
 */
const usePartnersAPI = () => {
  /**
   * @function fetchList
   * @description - API function that returns a list of current & available Oshi partners
   * @returns { Partner[] }
   */
  const fetchList = async () => {
    try {
      return await API.get('oshiAPI', `/service/partners`);
    } catch (error) {
      throw new Error('Fetching partner list failed');
    }
  };

  return {
    fetchList,
  };
};

export default usePartnersAPI;
