/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:a2b27671-ea7f-4263-9f4b-e951387dc8e9',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_VzrPEqycf',
  aws_user_pools_web_client_id: '3336v7063c1cm7j49uk86qmqkm',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_cloud_logic_custom: [
    {
      name: 'oshiAPI',
      endpoint: 'https://g3jq5uten6.execute-api.us-east-1.amazonaws.com/merge',
      region: 'us-east-1',
    },
  ],
  aws_appsync_graphqlEndpoint:
    'https://qbe6aor7efb67hufgxtygkbyri.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-z6jflmnnznhntmbhrdbpyeyz5u',
  aws_mobile_analytics_app_id: '567d3712a3a14c38994211b689a3ba03',
  aws_mobile_analytics_app_region: 'us-east-1',
  aws_dynamodb_all_tables_region: 'us-east-1',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'authsession-merge',
      region: 'us-east-1',
    },
    {
      tableName: 'cachedata-merge',
      region: 'us-east-1',
    },
    {
      tableName: 'cacheddata-merge',
      region: 'us-east-1',
    },
    {
      tableName: 'authsessions-merge',
      region: 'us-east-1',
    },
    {
      tableName: 'multimediameta-merge',
      region: 'us-east-1',
    },
  ],
  aws_user_files_s3_bucket: 'multimedia121910-merge',
  aws_user_files_s3_bucket_region: 'us-east-1',
};

export default awsmobile;
