import React from 'react';
import styled from 'styled-components';

import Button from './Button';

const OshiLink = React.forwardRef(
  ({ onClick, buttonTitle, styles = {}, containerStyles = {} }, ref) => {
    const mergedStyles = {
      ...styles,
      backgroundColor: 'transparent',
    };
    return (
      <Container style={containerStyles}>
        <Button btnAs='link' onClick={onClick} styles={mergedStyles} ref={ref}>
          {buttonTitle}
        </Button>
      </Container>
    );
  }
);

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  ${Button} button {
    width: auto;
  }
`;

export default OshiLink;
