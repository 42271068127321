/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import OshiHeader from '../components/OshiHeader';
import Button from '../components/Button';
import OnboardingImage from '../assets/off-boarding.png';

import {
  OSHI_INFO_BANNER_TYPE,
  OSHI_INFO_BANNER_SENTIMENT,
} from '../constants/ui';
import {
  userZipOffboardingBackButtonEvent,
  userZipOffboardingSubmitEvent,
  pageViewEvent,
} from '../actions/segment';
import AnimatedRoute from '../components/AnimatedRoute';
import OshiInfoBanner from '../components/OshiInfoBanner';
import OshiNextButton from '../components/OshiNextButton';
import useOffboardingUILogic from '../hooks/useOffboardingUILogic';

const OffBoarding = ({
  userZipOffboardingBackButtonEvent: userZipOffboardingBackButton,
  userZipOffboardingSubmitEvent: userZipOffboardingSubmit,
  pageViewEvent: pageView,
}) => {
  const {
    isGSFOffboarding,
    isGSFFederalOffboarding,
    isGSFNoStateOffboarding,
    partner,
    address,
    getOffboardingDescription,
    getOffboardingTitle,
    handleBackToCompanyClick,
    handleBackArrow,
    handleContactSupportClick,
  } = useOffboardingUILogic({
    pageViewEvent: pageView,
    userZipOffboardingSubmit,
  });

  const getGSFMessageUIComponent = () => {
    if (isGSFNoStateOffboarding) {
      return (
        <>
          <DualBannerContainer>
            <OshiInfoBanner
              sentiment={OSHI_INFO_BANNER_SENTIMENT.ERROR}
              title='Availability In Your Area'
              description={`We're not yet available in your area.`}
              type={OSHI_INFO_BANNER_TYPE.REGULAR}
            />
            <OshiInfoBanner
              title='State'
              description={address.stateName}
              type={OSHI_INFO_BANNER_TYPE.INFO}
            />
          </DualBannerContainer>
          <DualBannerContainer>
            <OshiInfoBanner
              sentiment={OSHI_INFO_BANNER_SENTIMENT.SUCCESS}
              title='Health Benefits Coverage'
              type={OSHI_INFO_BANNER_TYPE.REGULAR}
            />
            <OshiInfoBanner
              title='Health Plan'
              description={partner.name}
              type={OSHI_INFO_BANNER_TYPE.INFO}
            />
          </DualBannerContainer>
        </>
      );
    }
    if (isGSFFederalOffboarding) {
      return (
        <OshiInfoBanner
          title='Health Plan'
          description={partner.name}
          type='info'
        />
      );
    }
    return (
      <OshiInfoBanner
        title={partner.id ? 'Health Plan' : 'Employer'}
        description={partner.id ? partner.id : partner.name}
        type='info'
      />
    );
  };

  if (isGSFOffboarding) {
    return (
      <AnimatedRoute
        animationDirection='mount'
        showProgressBar={false}
        title={getOffboardingTitle()}
      >
        <SubcopyContainer>
          <Typography
            type='paragraph'
            styles={{ color: palette.coolGray500, fontWeight: '400' }}
          >
            {getOffboardingDescription()}
          </Typography>
        </SubcopyContainer>
        <BannerContainer>{getGSFMessageUIComponent()}</BannerContainer>
        <OshiNextButton
          onClick={handleBackToCompanyClick}
          buttonTitle='Back To OshiHealth.com'
        />
      </AnimatedRoute>
    );
  }

  return (
    <PageContainer>
      <Background />
      <HeaderContainer>
        <OshiHeader
          type='back'
          action={handleBackArrow}
          segment={userZipOffboardingBackButton}
        />
      </HeaderContainer>
      <FlexContainer>
        <div>
          <OFImage
            src={OnboardingImage}
            alt='two hands reaching for eachother'
          />
        </div>
        <PageTitle>
          <Typography
            type='boldRegular'
            styles={{
              color: palette.darkPrimaryText,
              fontFamily: 'Source Serif Pro, serif',
            }}
          >
            Thanks for your interest!
          </Typography>
        </PageTitle>

        <ParagraphContainer>
          <Typography
            type='default'
            styles={{ color: palette.darkPrimaryText, marginBottom: '22px' }}
          >
            At the moment, we’re not able to provide care in your state.
          </Typography>

          <Typography
            type='default'
            styles={{ color: palette.darkPrimaryText }}
          >
            If you believe you’ve received this in error please contact our Care
            Support.
          </Typography>
        </ParagraphContainer>
        <Typography
          type='boldSmall'
          styles={{
            color: palette.darkPrimaryText,
            textDecoration: 'underline',
            fontSize: '14px',
            letterSpacing: '0.06em',
            marginTop: '5px',
            cursor: 'pointer',
          }}
        >
          <Button onClick={handleContactSupportClick}>Contact Support</Button>
        </Typography>
      </FlexContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: static;
  width: 100vw;
  height: 100vh;
`;
const HeaderContainer = styled.div`
  display: flex;
  position: relative;
`;
const Background = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  background: linear-gradient(180deg, #fff 0%, #f4faf8 100%);
`;
const FlexContainer = styled.div`
  position: relative;
  @media (max-width: 420px) {
    top: 12vh;
  }
  top: 16vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  z-index: 2;
`;
const PageTitle = styled.div`
  width: 375px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 56px 0;
`;
const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 168px;
  text-align: center;
  margin-bottom: -25px;
  max-width: 420px;
  padding: 0 10px;
  @media (max-width: 420px) {
    margin-bottom: 0;
  }
`;

const OFImage = styled.img`
  height: 200px;
  width: 200px;
`;

const SubcopyContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
  letter-spacing: 0.04em;
  @media only screen and (min-width: 769px) {
    margin: 12px 0px 42px 0px;
  }
`;

const BannerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 24px -21px 0;
  width: 100vw;
  @media only screen and (min-width: 640px) {
    flex-direction: row;
    margin: 0;
    flex: 0;
    width: 100%;
  }
`;

const DualBannerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px;
  width: 100vw;
  border-radius: 0px;
  @media only screen and (min-width: 640px) {
    justify-content: space-between;
    width: 240px;
    &:nth-child(1) {
      padding-right: 24px;
    }
    & > div {
      border-radius: 8px;
    }
    & > div[type='regular'] {
      padding: 12px 0;
    }
  }
`;

export default connect(null, {
  userZipOffboardingBackButtonEvent,
  userZipOffboardingSubmitEvent,
  pageViewEvent,
})(OffBoarding);
