import React from 'react';

import usePatientLeadAPI from './usePatientLeadAPI';
import { localStorageGet } from '../utils/localStorageHelper';
import { getBirthdate } from '../utils/getBirthdate';
import { PATIENT_LEAD_STATUSES } from '../constants/salesforceTypes';
import { CURRENT_PARTNERS } from '../constants/subscriptionStatus';

const useGenericSignUpFunctions = () => {
  const gender = localStorageGet('gender');
  const birthdate = getBirthdate();
  const { savePatientLead } = usePatientLeadAPI();

  const updatePatientLead = React.useCallback(
    async ({ partner, address }) => {
      try {
        const leadUpdate = {
          status: PATIENT_LEAD_STATUSES.QUALIFIED,
          zip_code: address.zip,
          partner,
          state: address.state,
          street: address.streetAddress,
          city: address.city,
          gender,
          birthdate,
        };

        if (address.apartment) {
          leadUpdate.address2 = address.apartment;
        }

        await savePatientLead('update', leadUpdate);
      } catch (e) {
        console.log('error', e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gender, birthdate]
  );

  const isFirstHealthPlanPartnerById = (partnerId) => {
    return (
      partnerId === CURRENT_PARTNERS.AETNA ||
      partnerId === CURRENT_PARTNERS.UHC ||
      partnerId === CURRENT_PARTNERS.BLUE_CROSS ||
      partnerId === CURRENT_PARTNERS.FIREFLY
    );
  };

  const findSelectedPartnerByList = (list, selectedPartner) =>
    list.find((it) => it.system_partner_id === selectedPartner);

  return {
    updatePatientLead,
    isFirstHealthPlanPartnerById,
    findSelectedPartnerByList,
  };
};

export default useGenericSignUpFunctions;
