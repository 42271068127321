import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { localStorageGet, localStorageSave } from '../utils/localStorageHelper';
import { getBirthdate } from '../utils/getBirthdate';
import usePatientLeadAPI from './usePatientLeadAPI';
import { FEDERAL_PARTNERS } from '../constants/subscriptionStatus';
import { PATIENT_LEAD_STATUSES } from '../constants/salesforceTypes';

/**
 * @module useOffboardingUILogic/hooks
 * @description Custom hook that handles offboarding UI logic functionalities for all sign up offboarding use cases
 * It handles variety of offboarding UI logic based on either user's regular sign up flow or generic sign up flow.
 * @param {{
 *    userZipOffboardingSubmit(): SegmentEvent,
 *    pageViewEvent(token: string): SegmentEvent
 * }} segmentObj - Segment object which contains segment event functions to be called in different UI events
 * @returns
 */
const useOffboardingUILogic = ({ userZipOffboardingSubmit, pageViewEvent }) => {
  const gender = localStorageGet('gender');
  const { lead } = localStorageGet('lead');
  const email = localStorageGet('email');
  const birthdate = getBirthdate();
  const history = useHistory();
  const { savePatientLead } = usePatientLeadAPI();
  const partnerFromLS = localStorageGet('partner') || null;
  const { address = null, partner = null } = history?.location?.state;
  const aPartner = partner || partnerFromLS;
  const isGenericSignUpFlow = useSelector(
    (state) => state.uiReducer?.isGenericSignupFlow
  );
  const isGSFOffboarding = Boolean(isGenericSignUpFlow && address && aPartner);
  const isGSFNoStateOffboarding = Boolean(
    isGSFOffboarding && aPartner?.notFoundState
  );
  const isGSFFederalOffboarding = Boolean(
    isGSFOffboarding &&
      aPartner?.id === FEDERAL_PARTNERS.MEDICARE_MEDICAID_MASSHEALTH
  );

  const updatePatientLead = React.useCallback(async () => {
    if ((address && aPartner) || address) {
      try {
        const isPartnerObj = typeof aPartner === 'object';
        const { zip, apartment, streetAddress, state, city } = address;
        const updateLead = {
          zip_code: zip,
          partner: isPartnerObj ? aPartner.id : aPartner,
          status: PATIENT_LEAD_STATUSES.UNQUALIFIED,
          street: streetAddress,
          state,
          city,
          gender,
          birthdate,
        };

        if (apartment) {
          updateLead.address2 = apartment;
        }

        if (isPartnerObj && aPartner?.name) {
          updateLead.payer = aPartner.name;
        }

        await savePatientLead('update', updateLead);
      } catch (error) {
        console.log('err', error);
      }
    } else {
      handleBackArrow();
    }
    // eslint-disable-next-line
  }, [address, aPartner]);

  const getOffboardingTitle = React.useCallback(() => {
    if (isGSFNoStateOffboarding || isGSFFederalOffboarding) {
      return `We'll let you know when Oshi Health is available to you.`;
    }
    return `Thanks for your interest in Oshi Health!`;
  }, [isGSFNoStateOffboarding, isGSFFederalOffboarding]);

  const getOffboardingDescription = React.useCallback(() => {
    if (isGSFNoStateOffboarding || isGSFFederalOffboarding) {
      return `It looks like Oshi Health isn't yet available to you for the reason listed below. Our network is growing quickly and we hope to support your GI journey soon.`;
    }
    return `Our network is growing quickly and we hope to support your GI journey soon.`;
  }, [isGSFNoStateOffboarding, isGSFFederalOffboarding]);

  React.useEffect(() => {
    if (!lead) {
      localStorageSave('lead', { lead: email });
    }
  }, [lead, email]);

  React.useEffect(() => {
    ((address && aPartner) || address) && updatePatientLead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, aPartner]);

  React.useEffect(() => {
    if (isGSFFederalOffboarding) {
      pageViewEvent(
        'Signup - Medicare/Medicaid/MassHealth Offboarding - Page View'
      );
    } else if (isGSFNoStateOffboarding) {
      pageViewEvent('Signup - State Offboarding - Page View');
    } else if (isGSFOffboarding) {
      pageViewEvent('Signup - Health Plan Not Listed Offboarding - Page View');
    } else {
      pageViewEvent('Signup - Zip Offboarding - Page View');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGSFFederalOffboarding, isGSFNoStateOffboarding, isGSFOffboarding]);

  const handleBackArrow = () => {
    history.goBack();
  };

  const handleBackToCompanyClick = () => {
    document.location = 'https://oshihealth.com';
  };

  const handleContactSupportClick = () => {
    userZipOffboardingSubmit();
    window.location.href = 'mailto:carecoordinator@oshihealth.com';
  };

  return {
    isGSFOffboarding,
    isGSFNoStateOffboarding,
    isGSFFederalOffboarding,
    partner: aPartner,
    address,
    getOffboardingTitle,
    getOffboardingDescription,
    handleBackArrow,
    handleBackToCompanyClick,
    handleContactSupportClick,
  };
};

export default useOffboardingUILogic;
