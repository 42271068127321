/* eslint-disable default-case */
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import OshiProgressBar from './OshiProgressBar';
import {
  regularSignupRouteList,
  genericSignupRouteList,
} from '../constants/routes';

import { palette } from '../theme/palette';
import {
  HeaderLogoCicrle,
  CirlceLogo,
  HeaderLogo,
  // ArrowRight,
  ArrowLeft,
  CloseIcon,
  MarpaiOshiLogo,
} from '../assets/svgs';
import { CURRENT_PARTNERS } from '../constants/subscriptionStatus';

function OshiHeader({
  type,
  action,
  segment,
  testId,
  logoType = null,
  text,
  style,
  showProgressBar = false,
}) {
  const isGenericSignupFlow = useSelector(
    (store) => store.uiReducer?.isGenericSignupFlow
  );

  const backHandler = () => {
    action();
    if (segment) {
      segment();
    }
  };

  function getCenteredLogo() {
    if (text) {
      return <Text>{text}</Text>;
    }
    if (logoType === 'circle') {
      return <HeaderLogoCicrle />;
    }
    if (logoType === 'oshiLogo') {
      return <CirlceLogo />;
    }
    if (logoType === CURRENT_PARTNERS.MARPAI) {
      return <MarpaiOshiLogo />;
    }

    return <HeaderLogo />;
  }

  return (
    <Container
      showProgressBar={showProgressBar}
      data-testid={testId || ''}
      style={style}
    >
      <PaddedContainer>
        {type === 'back' && (
          <LeftIconContainer onClick={backHandler}>
            <ArrowLeft color={palette.greyText} />
          </LeftIconContainer>
        )}
        {type === 'close' && (
          <LeftIconContainer onClick={backHandler}>
            <CloseIcon color={palette.greyText} />
          </LeftIconContainer>
        )}

        <CenterIconContainer fullflex={Boolean(type) === false}>
          {getCenteredLogo()}
        </CenterIconContainer>
        {/* place this empty container to the right as long a left component is available in order to horizontally center oshi logo */}
        {type ? (
          <RightIconContainer onClick={action}>
            {/* {type === 'back' && (<ArrowRight color={palette.greyText} /> )}*/}
          </RightIconContainer>
        ) : null}
      </PaddedContainer>
      {showProgressBar ? (
        <OshiProgressBar
          progressBarList={
            isGenericSignupFlow
              ? genericSignupRouteList
              : regularSignupRouteList
          }
        />
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  z-index: 1;
  flex: 1;
  width: 100%;
  height: ${(props) => (props.showProgressBar ? '64px' : '58px')};
  position: absolute;
  top: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${palette.white};
  margin-bottom: ${(props) => (props.showProgressBar ? '16px' : '0')};

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  box-sizing: border-box;
  @media only screen and (min-width: 769px) {
    margin-top: 32px;
  }
`;

const PaddedContainer = styled.div`
  width: 100%;
  display: flex;
  height: 58px;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media only screen and (min-width: 769px) {
    padding: 0 20px;
  }
`;

const CenterIconContainer = styled.div`
  display: flex;
  flex: ${({ fullflex }) => (fullflex ? '12' : '10')};
  justify-content: center;
`;

const LeftIconContainer = styled.div`
  display: flex;
  left: 24px; // left button width
  flex: 1;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const RightIconContainer = styled.div`
  display: flex;
  flex: 1;
  right: 24px; // right button width
  align-items: center;
  cursor: pointer;
  justify-content: center;
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const Text = styled.span`
  font-size: 16px;
  color: ${palette.navy500};
  font-family: 'Usual', sans-serif;
  font-weight: 600;
`;

export default OshiHeader;
