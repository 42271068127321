import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import useCommonPartnersUILogic from '../hooks/useCommonPartnersUILogic';
import useCurrentPartnersPageUILogic from '../hooks/useCurrentPartnersPageUILogic';
import useGenericSignUpFunctions from '../hooks/useGenericSignUpFunctions';
import { setIsFetching } from '../actions';
import AnimatedRoute from '../components/AnimatedRoute';
import withTracker from '../wrappers/withTracker';
import OshiLink from '../components/OshiLink';
import OshiNextButton from '../components/OshiNextButton';
import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import OshiSelect from '../components/OshiSelect';
import OshiSelectOption from '../components/OshiSelectOption';

/**
 * @function CurrentPartners
 * @description Custom page that shows current health plans page for the new generic sign up flow.
 * It fetches partner list to parse and display them as custom UI select-option component for the user
 * to select as new health plans. If user finds their enrolled Health plan then app process with sign up flow.
 * If user isn't enrolled to displayed health plans, the page offers to record user's current health
 * plan & employer in the New Partner page then the app proceeds with offboarding process.
 * Additionally handles custom UI events like navigating to New Partner page in case the user doesn't
 * find their Health plan within Oshi's current partners.
 * @param {(state: boolean): void} setIsFetching - Redux store action-based function to show/hide loading mask screen
 * @returns {React.ReactElement}
 */
const CurrentPartners = ({ setIsFetching }) => {
  const { parseList } = useCurrentPartnersPageUILogic();
  const {
    isFirstHealthPlanPartnerById,
    updatePatientLead,
    findSelectedPartnerByList,
  } = useGenericSignUpFunctions();

  const {
    partners,
    selectedPartner,
    animationDirection,
    setAnimationDirection,
    handleSelectPartnerClick,
    handleNewPartnerBtnClick,
    handleContinueBtnClick,
  } = useCommonPartnersUILogic({
    setIsFetching,
    parseList,
    updatePatientLead,
    isFirstHealthPlanPartnerById,
    findSelectedPartnerByList,
  });

  return (
    <AnimatedRoute
      nextRoute='/password'
      title={`Let's see if your employer covers Oshi Health.`}
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Container>
        <Typography
          type={'paragraph'}
          styles={{ fontWeight: '400', color: palette.coolGray500 }}
        >
          The following employers currently cover Oshi Health GI services. Use
          the dropdown below to select your employer or the employer through
          which you receive health benefits.
        </Typography>
      </Container>
      <OshiSelect
        name='partners'
        label='Employer'
        storageKey={'selectedPartner'}
        onChange={handleSelectPartnerClick}
        style={{ marginTop: '24px' }}
      >
        {partners.map((p) => (
          <OshiSelectOption
            key={p.system_partner_id}
            value={p.system_partner_id}
            label={p.display_name}
          />
        ))}
      </OshiSelect>
      <OshiNextButton
        onClick={handleContinueBtnClick}
        disabled={!selectedPartner}
        buttonTitle='Continue'
      >
        <OshiLink
          onClick={handleNewPartnerBtnClick}
          containerStyles={{ justifyContent: 'center', marginTop: 10 }}
          styles={{
            textTransform: 'initial',
            width: '100%',
            fontWeight: '400',
            color: palette.navy500,
          }}
          buttonTitle='My employer is not on this list.'
        ></OshiLink>
      </OshiNextButton>
    </AnimatedRoute>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export default connect(null, { setIsFetching })(
  withTracker(CurrentPartners, 'Signup - Employer Page - Page View')
);
