import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';

import usePatientProfile from './usePatientProfile';
import { FOLLOW_UP_APPT_TYPE } from '../constants/appointment';
import { SIX_WEEK_MS, ONE_WEEK, FORTYEIGHT_HOURS } from '../constants/calendar';
import { segmentTypes } from '../constants';
import { appointmentTypes } from '../constants';
import { createTrackEvent } from '../actions/segment';
import { APPOINTMENT_ERROR } from '../constants/appointment';

function useAvailableAppointments(props) {
  const {
    native,
    appointmentTypeFromWebview,
    isReschedulingFromWebView,
    queryStringParameters,
    providerIdFromWebview,
    onFetchComplete,
  } = props;
  const history = useHistory();
  const availableAppointmentRedux = useSelector(
    (state) => state.appointmentReducer?.availableAppointments
  );
  const appointmentError = useSelector(
    (state) => state.appointmentReducer.error
  );
  const { patientProfile: patient } = usePatientProfile();
  const cognito_id = queryStringParameters.cognito_id || patient.cognito_id;
  const [availableAppointments, setAvailableAppointments] = useState(
    availableAppointmentRedux
  );
  const dispatch = useDispatch();

  if (!queryStringParameters.cognito_id) {
    queryStringParameters.cognito_id = patient.cognito_id;
  }

  const getApptsUrl =
    (native && appointmentTypeFromWebview === FOLLOW_UP_APPT_TYPE) ||
    isReschedulingFromWebView
      ? `/v2/providers/${providerIdFromWebview}/availability`
      : '/v2/providers/availability';

  const fetchAvailability = async (dateRange = {}) => {
    try {
            const fetchedAppointments = await API.get('oshiAPI', getApptsUrl, {
        queryStringParameters: { ...queryStringParameters, ...dateRange },
      });

      return fetchedAppointments;
    } catch (error) {
      createTrackEvent(segmentTypes.SERVER_ERROR, `Server error`);
      history.push('network-error', { redirectLink: window.location.href });
    }
  };

  async function getAvailableAppointments(dateRange) {
    const limit = new Date().getTime() + SIX_WEEK_MS;
    const sixWeeks = new Date();
    sixWeeks.setDate(new Date().getDate() + 42);
    
    let { from, to } = dateRange;
    let availability = [];
    
    availability = await fetchAvailability({ from, to });
    // If a week is empty, load the next until an appt is found or 6 weeks is hit
    if (availability.length === 0 && to < limit) {
      for (var i = 0; i < 6; i++) {
        const weekStart = new Date(from);
        weekStart.setDate(weekStart.getDate() + ONE_WEEK);

        const weekEnd = new Date(to);
        weekEnd.setDate(weekEnd.getDate() + ONE_WEEK);

        availability = await fetchAvailability({ from: weekStart.getTime(), to: weekEnd.getTime() });
        to = weekEnd.getTime();
  
        if (availability.length > 0 || to >= limit) break;
      }
    }

    onFetchComplete(availability);

    const appointments = { ...availableAppointments } || {};

    for (const appointment of availability) {
      appointments[appointment.available_from] = appointment;
    }

    setAvailableAppointments(appointments);

    dispatch({
      type: appointmentTypes.STORE_AVAILABLE_APPTS,
      availableAppointments: appointments,
      lastLoadedDate: to,
    });
  }

  useEffect(() => {
    const today = new Date();

    if (cognito_id) {
      !availableAppointments ||
      appointmentError === APPOINTMENT_ERROR.TIMESLOT_TAKEN
        ? getAvailableAppointments({ from: today.getTime() + FORTYEIGHT_HOURS, to: new Date(today.setDate(today.getDate() + 13)).getTime()})
        : onFetchComplete(availableAppointments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  return { availableAppointments, getAvailableAppointments };
}

export default useAvailableAppointments;
