/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { palette } from '../theme/palette';
import {
  ShowPasswordEye,
  DontShowPasswordEye,
  CheckMark,
} from '../assets/svgs';
import { inputLeaveEvent, RevealPasswordIconClick } from '../actions/segment';
import { callAll } from '../utils/functionHelpers';

const AnimatedInput = animated(InputMask);

const inputStates = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ERROR: 'ERROR',
  DISABLED: 'DISABLED',
};

const OshiInput = React.forwardRef((props, ref) => {
  const {
    type,
    label,
    error,
    disabled,
    autoFocus,
    mask,
    value,
    autoFill,
    onFocus,
    onBlur = null,
    onClick = null,
    formik,
    showCheckMark,
    segmentLabel,
    isValid,
    maxChar,
    secondaryLabel = null,
    containerStyles = {},
    labelStyles = {},
    secondaryLabelStyles = {},
    inputLeaveEvent,
    clearParentErrorOnFocus,
    RevealPasswordIconClick,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const getInputStatus = () => {
    if (error) {
      return inputStates.ERROR;
    }

    if (disabled) {
      return inputStates.DISABLED;
    }

    if (autoFocus && !isMobile) {
      return inputStates.ACTIVE;
    }

    return inputStates.INACTIVE;
  };

  const [inputStatus, setInputStatus] = useState(getInputStatus());
  useEffect(() => {
    if (error) {
      setInputStatus(inputStates.ERROR);
    }
    if (!error && inputStatus === inputStates.ERROR) {
      setInputStatus(inputStates.ACTIVE);
    }
    // eslint-disable-next-line
  }, [error]);

  function onInputBlur() {
    inputLeaveEvent(segmentLabel || `Need Segment Label ${label}`);
    if (!error) {
      setInputStatus(inputStates.INACTIVE);
    } else {
      setInputStatus(inputStates.ERROR);
    }
  }

  function handleOnFocus() {
    // basically cutting corners here to fit the
    // design needs
    clearParentErrorOnFocus && clearParentErrorOnFocus();
    setInputStatus(inputStates.ACTIVE);
  }

  const getInputStyles = React.useCallback(() => {
    switch (inputStatus) {
      case inputStates.ACTIVE:
        return {
          backgroundColor: palette.background,
          borderBottom: '2px solid',
          borderBottomColor: palette.mediumTurqoise,
          caretColor: palette.darkPrimaryText,
          titleColor: palette.coolGray,
          color: palette.navy,
        };
      case inputStates.DISABLED:
        return {
          backgroundColor: palette.background,
          borderBottom: `1px solid`,
          borderBottomColor: palette.greyBorderColor,
          titleColor: palette.coolGray,
          color: palette.greyBorderColor,
          caretColor: palette.greyText,
          opacity: 0.7,
        };
      case inputStates.ERROR:
        return {
          backgroundColor: palette.background,
          caretColor: palette.errorBackground,
          borderBottom: '2px solid',
          borderBottomColor: palette.error,
          titleColor: palette.coolGray,
          color: palette.error,
        };
    }
    // default state here
    return {
      backgroundColor: palette.background,
      borderBottom: '2px solid',
      borderBottomColor: palette.greyBorderColor,
      titleColor: palette.coolGray,
      color: palette.coolGray,
      caretColor: palette.greyText,
    };
  }, [inputStatus]);

  function getIcon() {
    switch (type) {
      case 'password':
        return showPassword ? (
          <ShowPasswordEye color={palette.greyBorderColor} />
        ) : (
          <DontShowPasswordEye color={palette.greyBorderColor} />
        );
    }

    return null;
  }

  function getCheckMark() {
    if (
      showCheckMark &&
      ((isValid && value.length > 0) ||
        (formik && formik.isValid && value.length > 0))
    ) {
      return <CheckMark color={palette.mediumTurqoise} />;
    }

    return null;
  }

  function handleIconClick() {}
  function handlePasswordIconClick() {
    setShowPassword(!showPassword);
    RevealPasswordIconClick();
  }

  const inputStyles = getInputStyles();
  const inputAnime = useSpring(inputStyles);
  const titleAnime = useSpring({ color: inputStyles.titleColor });

  return (
    <Container style={{ ...containerStyles }}>
      <Title style={{ ...titleAnime, ...labelStyles }}>{label}</Title>

      <InputContainer>
        <Input
          {...props}
          autoFocus={!isMobile && autoFocus}
          maxlength={maxChar || '39'}
          ref={ref}
          mask={mask}
          type={type === 'password' && showPassword ? 'text' : type}
          onBlur={callAll(onInputBlur, onBlur)}
          onFocus={callAll(handleOnFocus, onFocus)}
          onClick={callAll(onClick)}
          style={{ ...inputAnime }}
          autocomplete={autoFill ? 'on' : 'off'}
        />

        <IconContainer>
          <ChildrenContainer
            onClick={() => {
              return type === 'password'
                ? handlePasswordIconClick()
                : handleIconClick();
            }}
          >
            {getIcon()}
          </ChildrenContainer>

          {getCheckMark()}
        </IconContainer>
      </InputContainer>

      {secondaryLabel ? (
        <SubTitle style={{ ...secondaryLabelStyles }}>
          {secondaryLabel}
        </SubTitle>
      ) : null}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 5px;
  width: 100%;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 20px;
  display: flex;
  align-items: center;
`;

const ChildrenContainer = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Input = styled(AnimatedInput)`
  display: flex;
  border: none;
  height: 41px;
  margin-bottom: 8px;
  padding-left: 8px;
  font-family: 'Usual';
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.03em;
`;

const Title = styled(animated.p)`
  margin-bottom: 8px;
  font-size: 14px;
  font-family: 'Usual';
  line-height: 18px;
  letter-spacing: 0.05em;
`;

const SubTitle = styled.p`
  font-size: 13px;
  font-family: 'Usual';
  line-height: 19.5px;
  color: ${palette.coolGray500};asddad
`;

export default connect(
  null,
  { inputLeaveEvent, RevealPasswordIconClick },
  null,
  { forwardRef: true }
)(OshiInput);
