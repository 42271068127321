import React from 'react';
import styled from 'styled-components';
import { palette } from '../theme/palette';
import { ONBOARDING_APPT_TYPE } from '../constants/appointment';

function SymptomsTextArea({
  formik,
  handleSymptomsChange,
  enterSubmit,
  native,
  appointmentTypeFromWebview,
}) {
  if (
    !native ||
    (native && appointmentTypeFromWebview === ONBOARDING_APPT_TYPE)
  ) {
    return (
      <>
        <InputContainer>
          <TextInput
            id='symptoms'
            value={formik.values.symptoms}
            onChange={handleSymptomsChange}
            placeholder='Describe your symptoms here.'
            enterKey={enterSubmit}
          />
        </InputContainer>
      </>
    );
  }

  return null;
}

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  @media only screen and (min-width: 429px) {
  }
`;

const TextInput = styled.textarea`
  padding: 10px;

  width: 100%;
  height: 168px;

  @media only screen and (max-width: 429px) {
    height: 80px;
  }
  /* cool-gray / cool-gray-200 */

  border: 1px solid ${palette.coolGray200};
  border-radius: 8px;

  /* fine print */

  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 168%;
  /* or 20px */

  letter-spacing: 0.04em;
  font-feature-settings: 'liga' off;
  color: ${palette.coolGray};

  &:focus {
    border-color: ${palette.mediumTurqoise};
    height: 168px;
  }

  ::placeholder {
    color: ${palette.coolGray200};
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${palette.coolGray200};
  }
`;

export default React.memo(SymptomsTextArea);
