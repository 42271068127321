/* eslint-disable no-unused-expressions */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { setIsFetching } from '../actions';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import useAddressPageUILogic from '../hooks/useAddressPageUILogic';
import withTracker from '../wrappers/withTracker';
import OshiNextButton from '../components/OshiNextButton';
import {
  OffBoardingAvailableStateDebug,
  userConfirmAddressSubmitEvent,
} from '../actions/segment';
import OshiAutocomplete from '../components/OshiAutocomplete';
import { stateList } from '../constants/stateAbbreviations';

function Address({
  setIsFetching,
  OffBoardingAvailableStateDebug,
  userConfirmAddressSubmitEvent: userConfirmAddressSubmit,
}) {
  const {
    formik,
    disabled,
    errors,
    zipError,
    stateError,
    animationDirection,
    setAnimationDirection,
    handleOnKeyPress,
    isBtnPressed,
    isAPILoading,
    handleBtnClick,
    isGenericSignupFlow,
    stateSearchFn,
  } = useAddressPageUILogic({
    setIsFetching,
    OffBoardingAvailableStateDebug,
    userConfirmAddressSubmit,
  });

  return (
    <AnimatedRoute
      nextRoute={isGenericSignupFlow ? '/health-plan' : '/password'}
      title='Is this your correct address?'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Form onKeyDown={handleOnKeyPress}>
        <OshiInput
          id='streetAddress'
          type='text'
          label='Street Address*'
          segmentLabel='Patient Info - Street'
          isValid={formik.dirty && Object.keys(formik.errors).length === 0}
          onChange={formik.handleChange}
          value={formik.values.streetAddress}
          error={Object.keys(formik.errors).includes('streetAddress')}
          autoFocus
        />

        <OshiInput
          id='apartment'
          type='text'
          label='Street Address 2'
          segmentLabel='Patient Info - Apartment Number'
          isValid={formik.dirty && Object.keys(formik.errors).length === 0}
          onChange={formik.handleChange}
          value={formik.values.apartment}
        />

        <CityStateContainer>
          <div style={{ width: '45%', marginRight: '10%' }}>
            <OshiInput
              id='city'
              type='text'
              label='City*'
              segmentLabel='Patient Info - City'
              isValid={formik.dirty && Object.keys(formik.errors).length === 0}
              onChange={formik.handleChange}
              value={formik.values.city}
              error={Object.keys(formik.errors).includes('city')}
            />
          </div>
          <div style={{ width: '45%' }}>
          <OshiAutocomplete
              id='state'
              label='State*'
              value={formik.values.state}
              onChange={formik.handleChange}
              inputModel={formik}
              data={stateList}
              searchCriteria={stateSearchFn}
              extraProps={{
                segmentLabel: 'Patient Info - State',
                isValid:
                  formik.dirty && Object.keys(formik.errors).length === 0,
                error:
                  stateError || Object.keys(formik.errors).includes('state'),
              }}
            />
          </div>
        </CityStateContainer>

        <OshiInput
          id='zip'
          type='tel'
          label='Zip Code*'
          maxChar={5}
          segmentLabel='Patient Info - Zip'
          isValid={formik.dirty && Object.keys(formik.errors).length === 0}
          onChange={formik.handleChange}
          value={formik.values.zip}
          error={zipError || Object.keys(formik.errors).includes('zip')}
        />
      </Form>

      {errors && (
        <ErrorContainer>
          <Typography styles={{ color: palette.error }}>
            {zipError && 'Zipcode is invalid'}
            {!zipError &&
              !stateError &&
              'Please make sure all highlighted fields have been correctly filled.'}
          </Typography>
        </ErrorContainer>
      )}

      <OshiNextButton
        disabled={disabled}
        isLoading={isBtnPressed && isAPILoading}
        onClick={handleBtnClick}
        buttonTitle='This is correct'
      />
    </AnimatedRoute>
  );
}

const Form = styled.form`
  width: 100%;
  @media only screen and (min-width: 769px) {
    margin-bottom: 5px;
  }
`;

const CityStateContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const ErrorContainer = styled.div`
  position: relative;
  display: flex;
  height: 40px;
  text-align: center;
  justify-content: center;
  padding: 0px 20px 20px 20px;
`;
const mapStateToProps = ({ uiReducer }) => ({
  isFetching: uiReducer.isFetching,
});
export default connect(mapStateToProps, {
  setIsFetching,
  OffBoardingAvailableStateDebug,
  userConfirmAddressSubmitEvent,
})(withTracker(Address, 'Signup - Confirm Address - Page View'));
