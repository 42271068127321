import React from 'react';
import { useHistory } from 'react-router-dom';

import useIsAsyncMounted from './useIsAsyncMounted';
import usePartnersAPI from './usePartnersAPI';
import { localStorageGet, localStorageSave } from '../utils/localStorageHelper';
import { ROUTES } from '../constants/routes';
import { CURRENT_PARTNERS } from '../constants/subscriptionStatus';

/**
 * @function useCommonPartnersUILogic
 * @description Custom hook that shares common functionalities for partner-based UI pages.
 * It handles fetching current Oshi partners list to parse and display them as UI components,
 * also handles different UI events to navigate and pass data into different new UI pages
 * related to generic sign-flow.
 * @param {(state: any): void} setIsFetching - Redux action function to show/hide loading mask screen
 * @param {(list: Partner[]): PartnerUI[]} parseList - Parse-data based function to format partner list for UI
 * @returns {{
 *   partners: PartnerUI[],
 *   selectedPartner: (state: Partner): void,
 *   animationDirection: 'mount' | 'unmount',
 *   setAnimationDirection: (state: string): void,
 *   handleSelectPartnerClick: (id: string): void,
 *   handleNewPartnerBtnClick: (): void,
 *   handleContinueBtnClick: (): void
 * }}
 */
const useCommonPartnersUILogic = ({
  setIsFetching,
  parseList,
  updatePatientLead,
  isFirstHealthPlanPartnerById,
  findSelectedPartnerByList,
}) => {
  const history = useHistory();
  const [animationDirection, setAnimationDirection] = React.useState('mount');
  const [partnerList, setPartnerList] = React.useState([]);
  const [selectedPartner, setSelectedPartner] = React.useState(null);
  const { fetchList } = usePartnersAPI();
  const state = localStorageGet('state');
  const { address } = localStorageGet('address');
  const fromLS = localStorageGet('selectedPartner') || null;

  useIsAsyncMounted(fetchList, setPartnerList);

  const memoizedList = React.useMemo(() => {
    return parseList(partnerList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerList]);

  const foundPartner = React.useMemo(
    () =>
      memoizedList.find((p) => p.system_partner_id === fromLS?.selectedPartner),
    [fromLS, memoizedList]
  );

  React.useLayoutEffect(() => {
    setIsFetching(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    if (partnerList.length > 0 && memoizedList.length >= 0) {
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerList, memoizedList]);

  React.useLayoutEffect(() => {
    setSelectedPartner(
      foundPartner && fromLS?.selectedPartner ? fromLS?.selectedPartner : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundPartner]);

  const handleContinueBtnClick = React.useCallback(async () => {
    setIsFetching(true);
    try {
      const partner = selectedPartner;
      const isFirstHealthPlanPartner = isFirstHealthPlanPartnerById(partner);

      if (isFirstHealthPlanPartner) {
        setIsFetching(false);
        history.push({
          pathname: ROUTES.FEDERAL_PLAN,
          state: {
            partnerList,
          },
        });
        return;
      }

      if (partner === CURRENT_PARTNERS.OTHER) {
        setIsFetching(false);
        history.push({
          pathname: ROUTES.CURRENT_PARTNERS,
        });
        return;
      }

      const foundPartner = findSelectedPartnerByList(partnerList, partner);

      const foundPartnerState =
        foundPartner?.operatingStates?.includes(state) || null;

      if (!foundPartnerState) {
        setIsFetching(false);
        history.push({
          pathname: ROUTES.OFF_BOARDING,
          state: {
            address: {
              ...address,
              stateName: state,
            },
            partner: {
              id: partner,
              name: foundPartner.display_name,
              notFoundState: true,
            },
          },
        });
        return;
      }

      await updatePatientLead({ partner, address });

      setIsFetching(false);
      setAnimationDirection('unmount');
    } catch (e) {
      console.log('error', e);
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, selectedPartner, partnerList]);

  const handleNewPartnerBtnClick = () => {
    history.push({
      pathname: ROUTES.NEW_PARTNER,
      state: {
        address: {
          ...address,
          stateName: state,
        },
      },
    });
  };

  const handleSelectPartnerClick = (partnerId) => {
    if (partnerId !== CURRENT_PARTNERS.OTHER) {
      localStorageSave('selectedPartner', {
        selectedPartner: partnerId,
      });
    }
    setSelectedPartner(partnerId);
  };

  return {
    partners: memoizedList,
    selectedPartner,
    animationDirection,
    setAnimationDirection,
    handleSelectPartnerClick,
    handleNewPartnerBtnClick,
    handleContinueBtnClick,
  };
};

export default useCommonPartnersUILogic;
