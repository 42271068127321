import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter as Router } from 'react-router-dom';
import * as vwoSDK from 'vwo-node-sdk';
import styled from 'styled-components';

import Loader from './components/Loader';

import Routes from './routes';
import store from './store';
import {
  AppointmentContext,
  AvailableAppointmentsContext,
  VwoContext,
} from './utils/context';
import useWindowOrtientationChange from './hooks/useWindowOrientationChange';
import RightSidedBanner from './atoms/RightSidedBanner';
import UserProvider from './providers/UserProvider';

export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISH_KEY
);

function App() {
  const [appointment, setAppointment] = useState({
    Appointment: false,
    AppointmentTime: '',
    AppointmentTimeNode: '',
    AppointmentDate: '',
    AppointmentFullDate: '',
    SubscriptionStatus: '',
  });
  const [availableAppointments, setAvailableAppointments] = useState({
    dates: [],
    times: {},
  });
  const [vwoClientInstance, setVwoClientInstance] = useState({});

  useEffect(() => {
    async function initVWO() {
      const settingsFile = await vwoSDK.getSettingsFile(
        process.env.REACT_APP_VWO_ACCT_ID,
        process.env.REACT_APP_VWO_SDK_KEY
      );
      const vwoClient = vwoSDK.launch({
        settingsFile,
      });

      setVwoClientInstance(vwoClient);
    }
    initVWO();
  }, []);

  useWindowOrtientationChange();

  return (
    <Provider store={store}>
      <Loader />
      <VwoContext.Provider value={[vwoClientInstance, setVwoClientInstance]}>
        <AppointmentContext.Provider value={[appointment, setAppointment]}>
          <AvailableAppointmentsContext.Provider
            value={[availableAppointments, setAvailableAppointments]}
          >
            <UserProvider>
              <BodyContainer>
                <LeftContainer>
                  <Router>
                    <Routes />
                  </Router>
                </LeftContainer>

                <RightSidedBanner />
              </BodyContainer>
            </UserProvider>
          </AvailableAppointmentsContext.Provider>
        </AppointmentContext.Provider>
      </VwoContext.Provider>
    </Provider>
  );
}

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: row;
  position: relative;
  justify-content: stretch;
`;

const LeftContainer = styled.div`
  display: flex;
  width: 60%;
  max-width: 60%;

  @media (max-width: 1135px) {
    width: 100%;
    max-width: 100%;
  }
`;

export default App;
