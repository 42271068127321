import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ROUTES } from '../constants/routes';
import { localStorageGet, localStorageSave } from '../utils/localStorageHelper';
import usePatientAPI from './usePatientAPI';
import { UserContext } from '../utils/context';
import useCookieProcessing from './useCookieProcessing';
import { uiTypes } from '../constants';

const useUserRouteCheck = ({ setIsFetching }) => {
  const { loggedIn } = React.useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { didCompleteSignUpFlow } = localStorageGet('didCompleteSignUpFlow');
  const { getCookieValue, getCookieKeyValuePair } = useCookieProcessing();
  const { getAppointments } = usePatientAPI();

  async function determineUsersNextRoute() {
    try {
      setIsFetching(true);
      localStorageSave('subscriptionStatus', {
        subscriptionStatus: 'optum',
      });
      const appts = await getAppointments();
      localStorageSave('didCompleteSignUpFlow', {
        didCompleteSignUpFlow: true,
      });
      localStorageSave('onboardingAppointmentStatus', {
        onboardingAppointmentStatus: appts.onboarding,
      });

      if (appts.onboarding === 'none') {
        return history.replace(ROUTES.FIRST_APPT_CONFIRM);
      }

      if (appts.onboarding === 'pending' || appts.onboarding === 'finished') {
        return history.replace(ROUTES.FIRST_APPT_OVERVIEW);
      }

      return history.replace(ROUTES.SIGN_IN);
    } catch (err) {
      console.log('error', err);
      localStorageSave('didCompleteSignUpFlow', {
        didCompleteSignUpFlow: false,
      });
      localStorageSave('onboardingAppointmentStatus', {
        onboardingAppointmentStatus: null,
      });
      setIsFetching(false);
      return history.replace(ROUTES.NAME);
    }
  }

  React.useEffect(() => {
    if (loggedIn && Boolean(loggedIn.isUserAccountBeingCreated) === false) {
      determineUsersNextRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  // Save qstring cookie value into local storage
  React.useEffect(() => {
    // Save qstring cookie value into local storage
    localStorageSave('qstring', { qstring: '' });
    const qStringValue = getCookieKeyValuePair('qstring');
    if (qStringValue) {
      const extractedQString = getCookieValue(qStringValue);
      localStorageSave('qstring', {
        qstring: extractedQString,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check if partner param exists in URL when user lands on the name page
  // and determine if sign-up flow is generic or regular one
  React.useEffect(() => {
    // fire page view event only if the user had
    if (location.pathname.includes(ROUTES.NAME)) {
      const partnerName = location.pathname.split('/')[2] || null;
      localStorageSave('partner', { partner: partnerName });
      localStorageSave('selectedPartner', { selectedPartner: partnerName });
      const isGenericSignupFlow = Boolean(partnerName);
      dispatch({
        type: uiTypes.CHANGE_SIGN_UP_FLOW,
        payload: !isGenericSignupFlow,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Redirect to first book appt page if user already completed sign up flow
  React.useEffect(() => {
    if (
      loggedIn &&
      didCompleteSignUpFlow &&
      history.action === 'POP' &&
      location.pathname !== ROUTES.FIRST_APPT_CONFIRM
    ) {
      // Re-route to first book appt page if user already completed sign up flow
      // and is in a sign up route
      history.goForward();
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [location, didCompleteSignUpFlow, loggedIn]);
};

export default useUserRouteCheck;
