import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import { ROUTES } from '../constants/routes';

/**
 * @function useNewPartnerPageUILogic
 * @description Custom hook that handles new partner UI logic functionalities.
 * It handles the creation of new-partner formik form data and its submission that leads to
 * the off-boarding page.
 * @param {(state: any): void}
 * @param {(name: string): void} pageViewEvent - Analytics segment action function to trigger when UI page is loaded.
 * @returns {{
 *   animationDirection: 'mount' | 'unmount',
 *   setAnimationDirection: (state: string): void,
 *   formik: FormikConfig,
 *   handleJoinWaitlistClick: (): void,
 * }}
 */
const useNewPartnerPageUILogic = ({ setIsFetching, pageViewEvent }) => {
  const [animationDirection, setAnimationDirection] = useState('mount');
  const history = useHistory();
  const patientAddress = history?.location?.state?.address;
  const formik = useFormik({
    initialValues: {
      healthPlan: '',
      employer: '',
    },
    onSubmit: () => handleJoinWaitlistClick(),
  });

  const handleJoinWaitlistClick = () => {
    history.push({
      pathname: ROUTES.OFF_BOARDING,
      state: {
        address: patientAddress,
        partner: {
          id: formik.values.healthPlan,
          name: formik.values.employer,
        },
      },
    });
    setAnimationDirection('unmount');
  };

  useEffect(() => {
    if (!patientAddress) {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientAddress]);

  return {
    animationDirection,
    setAnimationDirection,
    formik,
    handleJoinWaitlistClick,
  };
};

export default useNewPartnerPageUILogic;
