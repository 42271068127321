/* eslint-disable react/prefer-stateless-function */
import React, { Component, createRef } from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import { connect } from 'react-redux';

import logoLoading from '../assets/localloader.json';

class Loader extends Component {
  constructor(props) {
    super(props);
    this.loadingContainerRef = createRef(null);
  }
  componentDidMount() {
    const { isFetching } = this.props;

    if (isFetching) {
      this.loadingContainerRef.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching !== this.props.isFetching &&
      this.props.isFetching
    ) {
      this.loadingContainerRef.current.focus();
    }
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: logoLoading,
    };
    const { isFetching } = this.props;

    if (!isFetching) return null;

    return (
      <PageContainer
        isFetching={isFetching}
        ref={this.loadingContainerRef}
        role='section'
      >
        <LottieContainer>
          <Lottie
            ariaLabel={'Loading screen, please wait'}
            ariaRole='text'
            options={defaultOptions}
            height={200}
            width={200}
          />
        </LottieContainer>
      </PageContainer>
    );
  }
}

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;

  background: #fff;
  position: fixed;
  z-index: 20;
`;

const LottieContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const mapStateToProps = ({ uiReducer }) => ({
  isFetching: uiReducer.isFetching,
});

export default connect(mapStateToProps)(Loader);
