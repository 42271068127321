/* eslint-disable no-plusplus */
import React from 'react';

import useDebounce from './useDebounce';
import useSearchableList, { defaultSearchFn } from './useSearchableList';

/**
 * @function useAutocomplete
 * @module hooks/useAutocomplete
 * @description Custom hook which handles the autocomplete UI component logic.
 * It handles a set of UI events and customized logic to mimic the behavior of a native
 * UI autocomplete component.
 * @param {{
 *    searchFn: Function|Promise,
 *    data: Array,
 *    id: string,
 *    inputModel: FormikState,
 *    debounceInMillis: number,
 * }} params
 * @returns {{
 *    list: Array,
 *    inputRef: React.Ref,
 *    listRef: React.Ref,
 *    hasPressedEnter: boolean,
 *    setRenderedList: Function,
 *    handleOnChange: Function,
 *    handleOnKeyDown: Function
 *    handleOnFocus: Function,
 *    handleOnItemClick: Function,
 * }}
 */
const useAutocomplete = ({
  searchFn = defaultSearchFn,
  data,
  id,
  inputModel,
  debounceInMillis,
}) => {
  const highlightedItem = {
    index: -1,
    action: null,
  };
  const inputRef = React.useRef(null);
  const listRef = React.useRef(null);
  const [hasPressedEnter, setHasPressedEnter] = React.useState(false);
  const [uiList, setUIList] = React.useState([]);
  const { debouncedValue } = useDebounce({
    value: inputModel.values.state,
    milliSeconds: debounceInMillis,
  });

  const { list, setList, setResultFn } = useSearchableList({
    searchFn,
    data,
  });

  const handleOnChange = (ev) => {
    hasPressedEnter && setHasPressedEnter(false);
  };

  const handleOnKeyDown = (ev) => {
    let newIndex = highlightedItem.index;
    const { which, key } = ev;
    if (uiList.length > 0) {
      switch (which) {
        case 38: {
          ev.preventDefault();
          if (newIndex > -1 && newIndex < uiList.length) {
            uiList[newIndex].classList.remove('selected');
          }
          newIndex--;
          if (newIndex > -1) {
            uiList[newIndex].classList.add('selected');
            highlightedItem.index = newIndex;
            highlightedItem.action = 'UP';
            inputRef.current.value = uiList[newIndex].textContent;
            const { height: itemHeight, top: itemTop } = uiList[
              newIndex
            ].getBoundingClientRect();
            const { top: listTop } = uiList[
              newIndex
            ].parentElement.getBoundingClientRect();
            if (itemTop < listTop) {
              listRef.current.scrollTo(0, (newIndex - 1) * itemHeight);
            }
          }
          if (newIndex < 0) {
            highlightedItem.index = -1;
            highlightedItem.action = null;
          }
          break;
        }
        case 40: {
          if (
            newIndex > -1 &&
            newIndex < uiList.length &&
            newIndex !== uiList.length - 1
          ) {
            uiList[newIndex].classList.remove('selected');
          }
          newIndex++;
          if (newIndex < uiList.length) {
            uiList[newIndex].classList.add('selected');
            highlightedItem.index = newIndex;
            highlightedItem.action = 'DOWN';
            inputRef.current.value = uiList[newIndex].textContent;
            const { bottom: itemBottom } = uiList[
              newIndex
            ].getBoundingClientRect();
            const { bottom: listBottom } = uiList[
              newIndex
            ].parentElement.getBoundingClientRect();
            if (itemBottom > listBottom) {
              listRef.current.scrollTo(
                0,
                Array.from(uiList)
                  .slice(0, newIndex)
                  .reduce((acc, it) => acc + it.clientHeight, 0)
              );
            }
          }
          break;
        }
        default:
          break;
      }
      if (key === 'Enter' && newIndex >= 0) {
        ev.preventDefault();
        ev.stopPropagation();
        inputModel.handleChange(id)(uiList[newIndex].textContent);
        setList([]);
        setUIList([]);
        setHasPressedEnter(true);
      }
    }
  };

  const handleOnFocus = (ev) => {
    const { value } = ev.target;
    const end = value.length;
    highlightedItem.index = -1;
    highlightedItem.action = null;
    ev.target.selectionStart = end;
    ev.target.selectionEnd = end;
    ev.target.setSelectionRange(end, end);
  };

  const handleOnItemClick = (ev) => {
    const { textContent } = ev.target;
    inputModel.handleChange(id)(textContent);
    setList([]);
    setUIList([]);
    setHasPressedEnter(true);
  };

  // Search debouced value on every input change
  React.useLayoutEffect(() => {
    function searchCharacter() {
      setList([]);
      setUIList([]);
      highlightedItem.index = -1;
      highlightedItem.action = null;
      setResultFn(debouncedValue);
    }
    debouncedValue && searchCharacter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  // Clean searchableList on initial render if there is input value
  React.useEffect(() => {
    setList([]);
    setUIList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    list,
    inputRef,
    listRef,
    highlightedItem,
    hasPressedEnter,
    setRenderedList: setUIList,
    handleOnChange,
    handleOnKeyDown,
    handleOnItemClick,
    handleOnFocus,
  };
};

export default useAutocomplete;
