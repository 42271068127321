import React from 'react';
import ContentLoader from 'react-content-loader';

import { palette } from '../theme/palette';


const renderCalendarDayLoaders = () => {
  const rectangles = [];

  const width = ( Math.min(window.innerWidth * .9, 550) / 7) - 8;

  for (var i = 0; i < 7; i++) {
    rectangles.push(
      <rect x={ (width + 8)*i } y='0' rx='8' ry='8' width={ width + 'px' } height='70px' />
    );
  }

  return rectangles;
}

export const DatePickerLoader = () => (
  <ContentLoader backgroundColor={ palette.turquoise50 } height='70px' width='100%'>
    { renderCalendarDayLoaders({ dayWidth: 70 }) }
  </ContentLoader>
);

const renderTimePickerLoaders = () => {
  const rectangles = [];

  const getColumns = () => {
    if (window.innerWidth > 768) return 6;  // Desktop
    if (window.innerWidth > 429) return 5;  // Tablet/small window
    return 3;                               // Mobile
  }
  const width = (Math.min(window.innerWidth * .9, 550) / getColumns()) - 6;

  for (var i = 0; i < getColumns(); i++) {
    rectangles.push(
      <rect x={ (width + 6) * i } y={0} rx='8' ry='8' width={ width + 'px'} height='41px' />
    );
  }
  return rectangles;
}

export const TimePickerLoader = () => (
  <ContentLoader backgroundColor={ palette.turquoise50 } height='100%' width='100%'>
    { renderTimePickerLoaders({ timeWidth: 109 }) }
  </ContentLoader>
)
