import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import useHealthPlanPageUILogic from '../hooks/useHealthPlanPageUILogic';
import useCommonPartnersUILogic from '../hooks/useCommonPartnersUILogic';
import useGenericSignUpFunctions from '../hooks/useGenericSignUpFunctions';
import withTracker from '../wrappers/withTracker';
import AnimatedRoute from '../components/AnimatedRoute';
import OshiRadioGroup from '../components/OshiRadioGroup';
import OshiRadioButton from '../components/OshiRadioButton';
import OshiNextButton from '../components/OshiNextButton';
import OshiLink from '../components/OshiLink';
import { ROUTES } from '../constants/routes';
import { setIsFetching } from '../actions';
import { palette } from '../theme/palette';
import useWindowSize from '../hooks/useWindowSize';

/**
 * @function HealthPlan
 * @description Custom page that shows Health Plan page for the new generic sign up flow.
 * It fetches partner list to parse and display them as custom UI radio button group for the user
 * to select as new health plans. If user finds their enrolled Health plan then app process with sign up flow.
 * If user isn't enrolled to displayed health plans, the page offers a third option to select other health plans
 * that might have user's expected health plan and just in case a last option to record their Health Plan not found
 * at current Oshi's Health plans partnership.
 * Additionally handles custom UI events like navigating to New Partner page in case the user doesn't
 * find their Health plan within Oshi's current partners.
 * @param {(state: boolean): void} setIsFetching - Redux store action-based function to show/hide loading mask screen
 * @returns {React.ReactElement}
 */
const HealthPlan = ({ setIsFetching: setFetching }) => {
  const {
    isFirstHealthPlanPartnerById,
    updatePatientLead,
    findSelectedPartnerByList,
  } = useGenericSignUpFunctions();
  const { parseList } = useHealthPlanPageUILogic({
    isFirstHealthPlanPartnerById,
  });
  const {
    partners,
    selectedPartner,
    animationDirection,
    setAnimationDirection,
    handleSelectPartnerClick,
    handleNewPartnerBtnClick,
    handleContinueBtnClick,
  } = useCommonPartnersUILogic({
    setIsFetching: setFetching,
    parseList,
    updatePatientLead,
    isFirstHealthPlanPartnerById,
    findSelectedPartnerByList,
  });
  const { screenSize } = useWindowSize();

  return (
    <AnimatedRoute
      nextRoute={ROUTES.FEDERAL_PLAN}
      title='Select your health plan below.'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Container>
        <OshiRadioGroup>
          {partners.map((p) => (
            <OshiRadioButton
              name='partner'
              value={p.system_partner_id}
              children={p.children}
              key={p.system_partner_id}
              componentKey={p.system_partner_id}
              isChecked={
                selectedPartner && p.system_partner_id === selectedPartner
              }
              onClick={() => handleSelectPartnerClick(p.system_partner_id)}
            />
          ))}
        </OshiRadioGroup>
        <OshiNextButton
          onClick={handleContinueBtnClick}
          disabled={!selectedPartner}
          buttonTitle='Continue'
          containerStyles={{
            paddingTop:
              screenSize.width >= 300 && screenSize.height <= 700 ? 12 : 0,
          }}
          buttonContainerStyles={{
            margin: 0,
          }}
        >
          <OshiLink
            onClick={handleNewPartnerBtnClick}
            containerStyles={{ justifyContent: 'center' }}
            styles={{
              textTransform: 'initial',
              fontWeight: '600',
              color: palette.navy500,
              width: '100%',
            }}
            buttonTitle='My health plan is not listed.'
          />
        </OshiNextButton>
      </Container>
    </AnimatedRoute>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export default connect(null, { setIsFetching })(
  withTracker(HealthPlan, 'Signup - Health Plan Selection - Page View')
);
