import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { palette } from '../theme/palette';
import { TimePickerLoader } from './SkeletonLoaders';

const TimePicker = React.forwardRef(
  (
    { labels, onSelect, initialSelect, testId, lastLoadedDate, selectedWeek },
    ref
  ) => {
    const [selected, setSelected] = useState('');

    useEffect(() => {
      setSelected('');
    }, [labels]);

    useEffect(() => {
      if (initialSelect) {
        setSelected(initialSelect);
      }
    }, [initialSelect]);

    const clickHandler = (node) => {
      setSelected(node);
      onSelect(labels[node], node);
    };

    if (new Date(selectedWeek).getTime() >= lastLoadedDate) {
      return <TimePickerLoader />;
    }

    return !labels || labels.length <= 0 ? (
      <NoTimesSection aria-live='polite' aria-describedby='noTimesAvailableId'>
        <NoTimesText
          type='subcopy'
          style={{ fontWeight: 300 }}
          id='noTimesAvailableId'
        >
          No appointments available.
        </NoTimesText>
      </NoTimesSection>
    ) : (
      <Container data-testid={testId || ''}>
        {labels &&
          labels.map((appointmentDate, index) => {
            return (
              <Block
                key={
                  appointmentDate.available_from +
                  appointmentDate.provider_id +
                  index
                }
                aria-labelledby={`dateId-${index}`}
                role='button'
                aria-pressed={selected === index}
                onClick={() => clickHandler(index)}
                highlighted={selected === index}
              >
                {moment(appointmentDate.available_from).format('LT')}
              </Block>
            );
          })}
      </Container>
    );
  }
);

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  row-gap: 8px;
  column-gap: 6px;
  scroll-snap-type: x mandatory;
  width: 100%;
  @media only screen and (min-width: 430px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @media only screen and (min-width: 769px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`;

const Block = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.highlighted ? palette.navy500 : palette.turquoise50};
  border-radius: 4px;
  color: ${(props) => (props.highlighted ? palette.navy50 : palette.navy500)};
  display: flex;
  font-family: Usual;
  font-size: 12px;
  font-weight: 600;
  height: 41px;
  justify-content: center;
  scroll-snap-align: start;
  cursor: pointer;
`;

const NoTimesSection = styled.section`
  display: flex;
  @media (max-width: 768px) {
    padding: 0 2%;
  }
`;

const NoTimesText = styled.p`
  text-align: center;
  color: ${palette.coolGray};
  font-size: 12px;
  font-family: Usual;
  font-weight: 400;
  line-height: 131.7%;
  letter-spacing: 0.06em;
  @media (max-width: 768px) {
    padding: 0 2%;
  }
`;

export default TimePicker;
